import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormArray } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ngx-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CarouselComponent
    }
  ]
})
export class CarouselComponent implements ControlValueAccessor {

    options: OwlOptions = {
        loop: true,
        margin: 30,
        autoplay: false,
        // autoplayTimeout: 600000,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 2
          },
          740: {
            items: 5
          },
          940: {
            items: 6
          }
        },
        nav: true
    }


    @Input() formControlName: string;
    @Input() name: string = null;
    @Input() inline: boolean = false;
    @Input() type: string;
    @Input() secure: boolean = false;
    @Input() min: number = 0;
    @Input() max: number = 999999999;
    @Input() label: string = "";
    @Input() placeholder: string = "";
    @Input() formGroup: FormArray;
    @Input() formControl: FormControl = new FormControl();
    @Input() errors: any;
    @Input() horizontal: boolean = false;
    @Input() classLabel: string = '';
    @Input() classInput: string = '';

    public aditionalstyle = "background-size: cover;background-repeat: no-repeat;background-position: center;background: url('./../.././../../assets/images/default-image.png');";

    imagensNews = [];

    value: any;

    touched = false;

    disabled = false;

    classHorizonalContent() {
      return this.horizontal ? 'row' : '';
    }
    classHorizonalLabel() {
      return this.horizontal ? 'col-lg-3' : '';
    }

    classHorizonalInput() {
      return this.horizontal ? 'col-lg-9' : '';
    }
    getClassInput(){
      if(this.displayErrors()){
        return `${this.classInput} ui-input-error`;

      }
      return this.classInput;
    }

    displayErrors(): boolean {
      if(this.formGroup){
        this.formControl = this.formGroup.get(this.formControlName) as FormControl;
      }
      if((this.formControl?.touched || this.formControl?.dirty)&& this.formControl?.invalid){
        return true;
      }
      return false;
    }

    getName(){
      if(this.name){
        return this.name;
      }else if(this.formControlName){
        return this.formControlName
      }
      return `INPUT${Math.max(0, 99999)}`;
    }

    removeImagen(i){
      this.value.splice(i, 1);
    }

    addImagen(e){
      this.imagensNews.push(e.filename);
      if(!this.value){
        this.value = [];
      }
      this.value.push(e.filename);
      this.markAsTouched();
      this.onChange(this.value);
    }

    isNew(filename){
      //console.log("SEARCH IMAGE:", filename, this.imagensNews, this.imagensNews.find(v => v == filename));
      return this.imagensNews.findIndex(v => v == filename) !== -1;
    }

    onChange = (quantity: number) => { };

    onTouched = () => { };

    writeValue(value: any) {
      //console.log("writeValue", value);
      this.value = value;
    }

    registerOnChange(onChange: any) {
      this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
      this.onTouched = onTouched;
    }

    markAsTouched() {
      if (!this.touched) {
        this.onTouched();
        this.touched = true;
      }
    }

    setDisabledState(disabled: boolean) {
      this.disabled = disabled;
    }


}
