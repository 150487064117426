import { Component, Input, SimpleChanges, OnChanges, OnInit, AfterViewInit, ViewChild, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup, FormControlName } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';

@Component({
  selector: 'ui-input-select',
  templateUrl: "input-select.component.html",
  styleUrls: ["input-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputSelectComponent
    }
  ]
})
export class UiInputSelectComponent implements ControlValueAccessor,OnInit, OnChanges  {

  @Input() formControlName: string;
  @Input() name: string = null;
  @Input() inline: boolean = false;
  @Input() multiple: boolean = false;

  @Input() title: string;
  @Input() items: Array<any>;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() typeahead: Subject<string> = new Subject<string>();
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = '';
  @Input('classContainer') classContainer: string = 'form-group';

  @ViewChild('inputselect') inputselect: NgSelectComponent;

  value: any;
  touched = false;
  disabled = false;
  loading = true;
  control: FormControlName;

  constructor(private injector: Injector) {

  }

  isBinding(){
    return this.bindLabel && this.bindValue;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items?.currentValue != changes.items?.previousValue) {
      this.items = changes.items.currentValue;
      this.loading = false;
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
  }

  classHorizonalContent() {
    return this.horizontal ? 'row' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput() {
    if (this.displayErrors()) {
      return `${this.classInput} ui-input-error`;
    }
    return this.classInput;
  }

  displayErrors(): boolean {

    if (this.formGroup) {
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }

    if ((this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
      return true;
    }
    return false;
  }

  getName() {
    if(this.label){
      return this.label;
    }else if(this.title){
      return this.title;
    }else if (this.name) {
      return this.name;
    } else if (this.formControlName) {
      return this.formControlName
    }
    return `INPUT${Math.max(0, 99999)}`;
  }

  getLabel(){

  }

  add(e){
    //console.log("add",e);
  };

  change(e) {

    let value: any = null;
    if (e && e[this.bindValue]) {
      value = e[this.bindValue];
    } else {
      value = e;
    }

    //this.control = this.injector?.get(FormControlName);
    /*
    if(this.control){
      this.formControl = this.control.control as FormControl;
    }
    */
    //console.log("ngOnInit: ",this.control);

    //console.log("CHANGE", this.value, this.transformValue(value));

    this.onChange(this.transformValue(value));

  }

  transformValue(value){
    if(this.multiple){
      if(Array.isArray(this.items) && Array.isArray(value)){
        let list = [];
        this.items.map(r => {
          let val = value.find(v => v[this.bindValue] == r[this.bindValue]);
          if(val){
            list.push(r[this.bindValue]);
          }
        });
        return list;
      }
    }
    return value;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onChange = (value: any) => {
    //console.log("onChange", value);
    this.inputselect?.writeValue(value);
    this.value = value;
  };

  onTouched = () => {};

  writeValue(value: any) {
      setTimeout(() => {
          this.inputselect?.writeValue(value);
          this.value = value;
      }, 10);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
