import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { MapDialogComponent } from '../map-position/map-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ui-input-geolocation',
  templateUrl: "input-geolocation.component.html",
  styleUrls: ["input-geolocation.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputGeolocationComponent
    }
  ]
})
export class UiInputGeolocationComponent implements ControlValueAccessor {

  @Input() nameLat: string = 'lat';
  @Input() nameLng: string = 'lng';
  @Input() formControlName: string;
  @Input() name: string = null;
  @Input() inline: boolean = false;
  @Input() type: string = 'imagen';
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = 'btn btn-default ui-input-geolocation-bottom';

  value: any;

  touched = false;

  disabled = false;

  constructor(public _dialog: MatDialog) {

  }

  classHorizonalContent() {
    return this.horizontal ? 'form-group row' : 'form-group';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput() {
    const clasIsvalue = this.getLat() == 0 && this.getLng() == 0 ? '' : 'checkin';
    if (this.displayErrors()) {
      return `${this.classInput} ${clasIsvalue} ui-input-error`;

    }
    return `${this.classInput} ${clasIsvalue}`;
  }

  getClassImage() {
    const clasIsvalue = this.getLat() == 0 && this.getLng() == 0 ? '' : 'checkin';
    if (this.displayErrors()) {
      return `${clasIsvalue} ui-input-error`;

    }
    return `${clasIsvalue}`;
  }

  getLat() {
    if (this.value && this.value[this.nameLat]) {
      return this.value[this.nameLat];
    }
    return '21.14834808288896';
  }
  getLng() {
    if (this.value && this.value[this.nameLng]) {
      return this.value[this.nameLng];
    }
    return '-86.85215419311524';
  }

  openDialog() {
    const center = new google.maps.LatLng(this.getLat(), this.getLng());
    const dialogRef = this._dialog.open(MapDialogComponent, {
      width: '100vw',
      data: { position: center },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let position = result.position.toJSON();
        this.value = this.value ? this.value : {};
        this.value[this.nameLat] = position.lat;
        this.value[this.nameLng] = position.lng;
        this.onChange(this.value);
      }
      this.markAsTouched();
    });
  }

  displayErrors(): boolean {
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if ((this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
      return true;
    }
    return false;
  }

  getName() {
    if(this.label){
      return this.label;
    }else if (this.name) {
      return this.name;
    } else if (this.formControlName) {
      return this.formControlName
    }
    return `INPUT${Math.max(0, 99999)}`;
  }

  change(position) {
    if (position) {
      //console.log("INPUT: ", position , this.formControl);
      this.value = this.value ? this.value : {};
      this.value[this.nameLat] = position.lat.toString();
      this.value[this.nameLng] = position.lng.toString();
      this.onChange(this.value);
    }
    this.markAsTouched();
  }

  onChange = (value: any) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}