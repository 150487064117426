import { Injectable } from '@angular/core';
import { NbToastrService, NbGlobalPhysicalPosition, NbGlobalLogicalPosition, NbIconConfig } from '@nebular/theme';

@Injectable()
export class ToastService {

    TOP_RIGHT = NbGlobalPhysicalPosition.TOP_RIGHT;
    TOP_LEFT = NbGlobalPhysicalPosition.TOP_LEFT;
    BOTTOM_LEFT = NbGlobalPhysicalPosition.BOTTOM_LEFT;
    BOTTOM_RIGHT = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
    TOP_END = NbGlobalLogicalPosition.TOP_END;
    TOP_START = NbGlobalLogicalPosition.TOP_START;
    BOTTOM_END = NbGlobalLogicalPosition.BOTTOM_END;
    BOTTOM_START = NbGlobalLogicalPosition.BOTTOM_START;

    private destroyByClick = true;
    private duration = 2000;
    private hasIcon = true;
    private preventDuplicates = false;

    constructor(private toastrService: NbToastrService) {

    }

    /**
     *
     * @param title
     * @param message
     * @param position
     */
    primary(title: string, message: string, position = null) {
        this.showToast('primary', title, message, position);
    }

    /**
     *
     * @param title
     * @param message
     * @param position
     */
    success(title: string, message: string, position = null) {
        this.showToast('success', title, message, position);
    }

    /**
     *
     * @param title
     * @param message
     * @param position
     */
    info(title: string, message: string, position = null) {
        this.showToast('info', title, message, position);
    }

    /**
     *
     * @param title
     * @param message
     * @param position
     */
    warning(title: string, message: string, position = null) {
        this.showToast('warning', title, message, position);
    }

    /**
     *
     * @param title
     * @param message
     * @param position
     */
    danger(title: string, message: string, position = null) {
        this.showToast('danger', title, message, position);
    }



    notificationChat(title: string, message: string, duration: number, position = null) {
        const iconConfig: NbIconConfig = { icon: 'message-circle-outline', pack: 'eva' };
        const config = {
            status: 'info',
            destroyByClick: true,
            duration,
            hasIcon: true,
            position: position ? position : NbGlobalPhysicalPosition.TOP_RIGHT,
            limit: 5,
            preventDuplicates: this.preventDuplicates,
            toastClass: 'chat-notification',
            icon: iconConfig
        };

        this.toastrService.show(message, title, config);
    }

    public showToast(type: string, title: string, body: string, position): void {

        const config = {
            status: type,
            destroyByClick: this.destroyByClick,
            duration: this.duration,
            hasIcon: this.hasIcon,
            position: position ? position : NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: this.preventDuplicates,
        };

        this.toastrService.show(body, title, config);
    }
}
