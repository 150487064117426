import { Injectable } from '@angular/core';
import {Howl, Howler} from 'howler';

@Injectable({
  providedIn: 'root'
})
export class HowlerService {

  notifyChat: string[] = [
    'assets/sound/nice_notify.mp3',
    'assets/sound/nice_notify.wav',
    'assets/sound/nice_notify.webm'
  ]

  constructor() {

  }

  chatMessage(): void{
    var sound = new Howl({
        src: this.notifyChat
    });
    sound.play();
  }

}
