<div class="{{classContainer}} {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} {{getClassInput()}}">

        <div *ngIf="value" class="ui-image-list-content">
            <div class="ui-image-list-item">
                <ui-image [filename]="value" [type]="type" [temp]="isNew()" [style]="style" [width]="width"
                    [height]="height"></ui-image>

                <button (click)="removeImagen()" class="btn btn-danger btn-round btn-sm ui-image-list-item-clear"><i
                        class="fa fa-trash"></i></button>

                <!--<button *ngIf="value"  input-image-crop [type]="type" [secure]="secure" (onChange)="addImagen($event)"
                        class="btn btn-success btn-round btn-sm ui-image-list-item-change"><i class="fa fa-folder mr-2"></i> {{title || 'Buscar Imagen'}}</button>-->
            </div>
        </div>

        <button *ngIf="!value" input-image-crop [type]="type" [secure]="secure" (onChange)="addImagen($event)"
            class="btn btn-success btn-sm"><i class="fa fa-folder mr-2"></i>{{title || 'Buscar Imagen'}}</button>

        <ui-input-errors [form]="formGroup" [formControl]="formControl" [formControlName]="formControlName">
        </ui-input-errors>

    </div>
</div>