<div *ngIf="!inline" class="{{classContainer}} {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}}">
        <input *ngIf="variant=='input'" [name]="getName()" [type]="type" [class]="getClassInput()" appnoautocomplete=""  [autocomplete]="autocomplete"
            [placeholder]="placeholder" [value]="value" (change)="change($event)" (focusout)="markAsTouched()" [disabled]="disabled" />

        <textarea *ngIf="variant=='textarea'" [class]="getClassInput()"
            [placeholder]="placeholder" [value]="value" [rows]="rows" (change)="change($event)" [disabled]="disabled"
            (focusout)="markAsTouched()"></textarea>

        <ui-input-errors [form]="formGroup" [formControl]="formControl" [formControlName]="formControlName" [name]="getName()">
        </ui-input-errors>
    </div>
</div>

<div *ngIf="inline" class="ui-input-container">
    <input [type]="type" [placeholder]="placeholder" [value]="value" (change)="change($event)"
        (focusout)="markAsTouched()" />
</div>
