import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormArray } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ngx-swipe-slider',
  templateUrl: './swipe-slider.component.html',
  styleUrls: ['./swipe-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwipeSliderComponent
    }
  ]
})
export class SwipeSliderComponent implements ControlValueAccessor, AfterViewInit {

  @Input() formControlName: string;
  @Input() name: string = null;
  @Input() inline: boolean = false;
  @Input() type: string;
  @Input() secure: boolean = false;
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormArray;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = '';
  @ViewChild("Slider", {static: false}) Slider: any;
  @ViewChild("ThumbsSlider") Thumbs: any;
  public aditionalstyle = "background-size: cover;background-repeat: no-repeat;background-position: center;background: url('./../.././../../assets/images/default-image.png');";

  imagensNews = [];
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 6,
      spaceBetween: 10
    },
    // when window width is >= 640px
    1600: {
      slidesPerView: 8,
      spaceBetween: 20
    }
  }
  value: any;

  touched = false;

  disabled = false;

  classHorizonalContent() {
    return this.horizontal ? 'row' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput(){
    if(this.displayErrors()){
      return `${this.classInput} ui-input-error`;

    }
    return this.classInput;
  }

  displayErrors(): boolean {
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if((this.formControl?.touched || this.formControl?.dirty)&& this.formControl?.invalid){
      return true;
    }
    return false;
  }

  getName(){
    if(this.name){
      return this.name;
    }else if(this.formControlName){
      return this.formControlName
    }
    return `INPUT${Math.max(0, 99999)}`;
  }

  removeImagen(i){
    this.Slider.nativeElement.swiper.removeSlide(i);
    this.Thumbs.nativeElement.swiper.removeSlide(i);
    this.Slider.nativeElement.swiper.update();
    this.Thumbs.nativeElement.swiper.update();

    
    this.value.splice(i, 1);

  
 
  }

  addImagen(e){
    this.imagensNews.push(e.filename);
    if(!this.value){
      this.value = [];
    }
    this.value.push(e.filename);
    this.markAsTouched();
    this.onChange(this.value);
  }

  isNew(filename){
    //console.log("SEARCH IMAGE:", filename, this.imagensNews, this.imagensNews.find(v => v == filename));
    return this.imagensNews.findIndex(v => v == filename) !== -1;
  }

  onChange = (quantity: number) => { };

  onTouched = () => { };

  writeValue(value: any) {
    //console.log("writeValue", value);
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngAfterViewInit(): void {

 setTimeout(() => {
    const thumb = this.Thumbs.nativeElement;

    Object.assign(thumb, {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        600: {
          slidesPerView: 5,

        },
        1024: {
          slidesPerView: 6,
        },
        1600: {
          slidesPerView: 8,
        },
        1900: {
          slidesPerView: 10,
        },
      },
    });

    thumb.update();
 }, 1000);
  
  }
  

  

}
