import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class UsuarioService {

    constructor(public api: ApiService) {

    }

    findAll<T>(params): Observable<any> {
        return this.api.get('user', params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`user/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('user/add', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('user/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`user/delete/${id}`);
    }

    findBy<T>(data: any): Observable<any> {
        return this.api.get('user/find/params', data);
    }
}
