import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-input-status',
  templateUrl: "input-status.component.html",
  styleUrls: ["input-status.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputStatusComponent
    }
  ]
})
export class UiInputStatusComponent implements ControlValueAccessor {
  
  @Input() formControlName: string;
  @Input() name: string = '';
  @Input() inline: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: string = 'text';
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;  
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = 'form-control';

  @Input() value: boolean = false;
  touched = false;

  classHorizonalContent() {
    return this.horizontal ? 'row' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput(){
    if(this.displayErrors()){
      return `${this.classInput} ui-input-error`;
    }
    return this.classInput;
  }

  displayErrors(): boolean {

    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if((this.formControl?.touched || this.formControl?.dirty)&& this.formControl?.invalid){
      return true;
    }
    return false;

  }

  getName(): string {
    return this.name || this.formControlName;
  }

  change(e) {

    this.value = e.checked;
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    this.onChange(this.value);
    
  }

  onChange = (quantity: boolean) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {      
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}