import { Component, ElementRef, Input, NgZone, OnInit, OnChanges, ViewChild, EventEmitter, Output, SimpleChanges } from '@angular/core';

import { MapService } from './map.service';
import { GeolocationService } from './geolocation.service';
import { GeocodingService } from './geocoding.service';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent, CurrentPosition, DialogData } from './map-dialog.component';
import { environment } from '../../../../environments/environment';
export const GOGLE_APIKEY = `${environment.google.apiKey}`;

@Component({
    selector: 'ui-map-position',
    templateUrl: './map-position.component.html',
    styleUrls: ['./map-position.component.scss']
})
export class MapPositionComponent implements OnInit, OnChanges {

    @Input() lat: number;
    @Input() lng: number;
    @Input() size: string = "200x200";
    @ViewChild('inputRef', { static: true }) inputRef: ElementRef;
    @Input() zoom: number = 12;
    @Input() position: google.maps.LatLng;
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();


    public mapStaticUri = `https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=300x300&key=${GOGLE_APIKEY}`;
    public center: google.maps.LatLng;
    public message: string;
    public warning: boolean = false;

    constructor(
        private map: MapService,
        private geolocation: GeolocationService,
        private geocoding: GeocodingService,
        public _dialog: MatDialog,
    ) {
        this.center = new google.maps.LatLng(41.910943, 12.476358);
        this.zoom = 18;

    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.lat != null && changes.lng != null) {
            this.center = new google.maps.LatLng(changes.lat.currentValue, changes.lng.currentValue);
            this.changeUrlMap();
        }
        if (changes.size?.currentValue != changes.size?.previousValue) {
            this.zoom = changes.size.currentValue;
            this.changeUrlMap();
        }

    }

    changeUrlMap() {
        const latitude = this.center.lat();
        const longitude = this.center.lng();
        this.mapStaticUri = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:red|label:C|${latitude},${longitude}&zoom=${this.zoom}&size=${this.size}&key=${GOGLE_APIKEY}`;
    }

    openDialog() {
        const dialogRef = this._dialog.open(MapDialogComponent, {
            width: '100vw',
            data: { position: this.center },
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            if (result) {
                let position = result.position.toJSON();
                this.onChange.emit(position);
            }
        });
    }

    getPositionTitle() {
        if (this.lat && this.lng) {
            return `${this.lat}, ${this.lng}`;
        }
        return "Seleccionar Coordenadas"
    }

    ngOnInit(): void {

    }

    getCurrentPosition(): void {
        this.warning = false;
        this.message = "";

        if (navigator.geolocation) {
            this.geolocation.getCurrentPosition().subscribe(
                (position: CurrentPosition) => {
                    if (this.center.lat() != position.coords.latitude &&
                        this.center.lng() != position.coords.longitude) {
                        // New center object: triggers OnChanges.
                        this.center = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        this.zoom = 11;

                        // Translates the location into address.
                        this.geocoding.geocode(this.center).forEach(
                            (results: google.maps.GeocoderResult[]) => {

                                this.setMarker(this.center, "your locality", results[0].formatted_address);
                            })
                            .then(() => console.log('Geocoding service: completed.'))
                            .catch((error: google.maps.GeocoderStatus) => {
                                if (error === google.maps.GeocoderStatus.ZERO_RESULTS) {
                                    this.message = "zero results";
                                    this.warning = true;
                                }
                            });
                    }
                },
                (error) => {
                    if (error.code > 0) {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                this.message = 'permission denied';
                                break;
                            case error.POSITION_UNAVAILABLE:
                                this.message = 'position unavailable';
                                break;
                            case error.TIMEOUT:
                                this.message = 'position timeout';
                                break;
                        }
                        this.warning = true;
                    }
                },
                () => console.log('Geolocation service: completed.'));

        } else {
            this.message = "browser doesn't support geolocation";
            this.warning = true;
        }
    }

    // Sets the marker & the info window.
    setMarker(latLng: google.maps.LatLng, title: string, content: string): void {
        // console.log("setMarker", latLng);
        this.map.deleteMarkers();
        // Sets the marker.
        this.position = latLng;
    }

}
