<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} {{getClassInput()}}">

        <div class="row" *ngIf="value && value.length > 0">
            <div class="ui-image-list-content col-lg-12 form-group">
                <div class="ui-image-list-item" *ngFor="let item of value; let i=index">
                    <ui-image [filename]="item" [type]="type" [secure]="secure" [temp]="isNew(item)" style="height: 12rem"></ui-image>
                    <button (click)="removeImagen(i)" class="btn btn-danger btn-round btn-sm ui-image-list-item-clear"><i
                            class="fa fa-trash"></i></button>
                </div>

                <!--
                <div input-image-crop (onChange)="addImagen($event)" class="ui-image-list-item input-image-list-item-add">
                    <div [style]="aditionalstyle">

                    </div>
                </div>
                -->

            </div>
        </div>

        <button input-image-crop [type]="type" [secure]="secure" (onChange)="addImagen($event)" class="btn btn-success btn-sm"><i
               class="fa fa-plus mr-2"></i>Nueva
            Imagen</button>


        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="formControlName"></ui-input-errors>

    </div>
</div>
