import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class VentasService {

    public $data: Array<any>;

    constructor(public api: ApiService) {

    }

    findAll<T>(params = null): Observable<any> {
        return new Observable((observer) => {
            this.api.get('ventas', params).subscribe((r) => {
                this.$data = r.data;
                return observer.next(r);
            }, (err) => {
                observer.error(err);
            });
        });
    }

    find<T>(tokenReservaFecha: string, params = null): Observable<any> {
        return this.api.get(`ventas/${tokenReservaFecha}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('operador-turistico/add', data);
    }

    update<T>(data: any): any {
        return this.api.post('ventas/registrar/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`operador-turistico/delete/${id}`);
    }

    getVendedores<T>(tokenReservaFecha: string, params = null): Observable<any> {
        return this.api.get(`ventas/get-ejecutivos/${tokenReservaFecha}`, params);
    }

    setEstadoVenta<T>(params = null): Observable<any> {
        return this.api.post(`ventas/set-estado-venta/`, params);
    }

}
