<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} ui-input-html-content">
        <div [class]="getClassInput()">
            <div [innerHtml]="value" class="ui-input-html-box"></div>
            <button class="btn btn-primary btn-sm" (click)="openDialog()"><i class="fa fa-edit"></i></button>
            <!--<ckeditor  [config]="{extraPlugins: 'divarea', height: '320' }" [value]="value" (blur)="change($event)"  ></ckeditor>-->
        </div>        
        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="getName()"></ui-input-errors>
    </div>    
</div>