import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class MembresiasBeneficosService {

  constructor(public api: ApiService){

  }

  findAll<T>(params): Observable<any> {
    return this.api.get('membresias-beneficios', params);
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`membresias-beneficios/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('membresias-beneficios/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('membresias-beneficios/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`membresias-beneficios/delete/${id}`);
  }
}
