import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class ImagenesService {

  constructor(public api: ApiService){

  }

  upload<T>(data): Observable<any> {    
    return this.api.formData('imagenes/', data);
  }

  upload_secure<T>(data): Observable<any> {    
    return this.api.formData('imagenes/secure', data);
  }

  

}
