import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    NbAuthModule,
    NbDummyAuthStrategy,
    NbPasswordAuthStrategy,
    NbAuthJWTToken,
    NbPasswordAuthStrategyOptions,
    NbAuthSimpleToken,
    NbAuthService
} from '@nebular/auth';
import {NbSecurityModule, NbRoleProvider} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {
    AnalyticsService,
    LayoutService,
    PlayerService,
    SeoService,
    StateService,
    ToastService,
    SettingsService,
    FormService,
    NotiflixService,
    HowlerService,
} from './utils';
import {UserData} from './data/users';
import {ElectricityData} from './data/electricity';
import {SmartTableData} from './data/smart-table';
import {UserActivityData} from './data/user-activity';
import {OrdersChartData} from './data/orders-chart';
import {ProfitChartData} from './data/profit-chart';
import {TrafficListData} from './data/traffic-list';
import {EarningData} from './data/earning';
import {OrdersProfitChartData} from './data/orders-profit-chart';
import {TrafficBarData} from './data/traffic-bar';
import {ProfitBarAnimationChartData} from './data/profit-bar-animation-chart';
import {TemperatureHumidityData} from './data/temperature-humidity';
import {SolarData} from './data/solar';
import {TrafficChartData} from './data/traffic-chart';
import {StatsBarData} from './data/stats-bar';
import {CountryOrderData} from './data/country-order';
import {StatsProgressBarData} from './data/stats-progress-bar';
import {VisitorsAnalyticsData} from './data/visitors-analytics';
import {SecurityCamerasData} from './data/security-cameras';

import {UserService} from './mock/users.service';
import {ElectricityService} from './mock/electricity.service';
import {SmartTableService} from './mock/smart-table.service';
import {UserActivityService} from './mock/user-activity.service';
import {OrdersChartService} from './mock/orders-chart.service';
import {ProfitChartService} from './mock/profit-chart.service';
import {TrafficListService} from './mock/traffic-list.service';
import {EarningService} from './mock/earning.service';
import {OrdersProfitChartService} from './mock/orders-profit-chart.service';
import {TrafficBarService} from './mock/traffic-bar.service';
import {ProfitBarAnimationChartService} from './mock/profit-bar-animation-chart.service';
import {TemperatureHumidityService} from './mock/temperature-humidity.service';
import {SolarService} from './mock/solar.service';
import {TrafficChartService} from './mock/traffic-chart.service';
import {StatsBarService} from './mock/stats-bar.service';
import {CountryOrderService} from './mock/country-order.service';
import {StatsProgressBarService} from './mock/stats-progress-bar.service';
import {VisitorsAnalyticsService} from './mock/visitors-analytics.service';
import {SecurityCamerasService} from './mock/security-cameras.service';
import {MockDataModule} from './mock/mock-data.module';
import {CurrencyService} from './api/currency.service';
import {RolesService} from './api/roles.service';
import {IdiomasService} from './api/idiomas.service';
import {PoliticasTerminosService} from './api/politicas-terminos.service';
import {PublicacionesService} from './api/publicaciones.service';
import {ServiciosService} from './api/servicios.service';
import {SubcategoriaService} from './api/subcategoria.service';
import {EstilosService} from './api/estilos.service';
import {TipoCobroService} from './api/tipo-cobro.service';
import {CategoriaBoletosService} from './api/categoria-boletos.service';
import {UbigeoService} from './api/ubigeo.service';
import {OperadorTuristicoService} from './api/operador-turistico.service';
import {MembresiasBeneficosService} from './api/membresias-beneficios';
import {TipoBeneficioService} from './api/tipo-beneficio.service';
import {ItinerarioService} from './api/itinerario.service';
import {ImagenesService} from './api/imagenes.service';
import {VentasService} from './api/ventas.service';
import {FechasService} from './api/fechas.service';
import {ExtrasService} from './api/extras.service';
import {FaqService} from './api/faq.service';
import {UsuarioService} from './api/usuario.services';
import {PublicacionesServicesService} from './api/publicaciones-services.service';
import {PaisesService} from './api/paises.service';
import {TipoDocumentoService} from './api/tipo-documento.service';
import {CuponesService} from './api/cupones.service';
import {PlanService} from './api/plan.service';
import {TiposBoletosService} from './api/tipos-boletos.service';
import {CambioMonedaService} from './api/cambio-moneda.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpInterceptorFormat} from './utils/http-interceptor-format.service';
import {environment} from '../../environments/environment';
import {RegistrosService} from './api/registros.service';
import { ObservacionesService } from './api/observaciones.service';
import {CategoriaService} from './api/categoria.service';
import {CategoriaBoletosIncluidosService} from './api/categoria-boletos-incluidos.service';
import {FechasAbiertasService} from './api/fechas-abiertas.service';
import {DiasSemanaService} from './api/dias-semana.service';
import {PreciosService} from './api/precios.service';
import {DevolucionService} from './api/devolucion.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { RoleProvider } from './utils/roles.service';
const socialLinks = [
    {
        url: 'https://github.com/akveo/nebular',
        target: '_blank',
        icon: 'github',
    },
    {
        url: 'https://www.facebook.com/akveo/',
        target: '_blank',
        icon: 'facebook',
    },
    {
        url: 'https://twitter.com/akveo_inc',
        target: '_blank',
        icon: 'twitter',
    },
];

const DATA_SERVICES = [
    {provide: UserData, useClass: UserService},
    {provide: ElectricityData, useClass: ElectricityService},
    {provide: SmartTableData, useClass: SmartTableService},
    {provide: UserActivityData, useClass: UserActivityService},
    {provide: OrdersChartData, useClass: OrdersChartService},
    {provide: ProfitChartData, useClass: ProfitChartService},
    {provide: TrafficListData, useClass: TrafficListService},
    {provide: EarningData, useClass: EarningService},
    {provide: OrdersProfitChartData, useClass: OrdersProfitChartService},
    {provide: TrafficBarData, useClass: TrafficBarService},
    {provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService},
    {provide: TemperatureHumidityData, useClass: TemperatureHumidityService},
    {provide: SolarData, useClass: SolarService},
    {provide: TrafficChartData, useClass: TrafficChartService},
    {provide: StatsBarData, useClass: StatsBarService},
    {provide: CountryOrderData, useClass: CountryOrderService},
    {provide: StatsProgressBarData, useClass: StatsProgressBarService},
    {provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService},
    {provide: SecurityCamerasData, useClass: SecurityCamerasService},
];
const SERVICE_PROVIDER = [
    CurrencyService,
    RolesService,
    IdiomasService,
    PoliticasTerminosService,
    TipoBeneficioService,
    MembresiasBeneficosService,
    PublicacionesService,
    ItinerarioService,
    FechasService,
    ExtrasService,
    FaqService,
    ServiciosService,
    SubcategoriaService,
    CategoriaService,
    EstilosService,
    TipoCobroService,
    UbigeoService,
    RoleProvider,
    RegistrosService,
    ImagenesService,
    UsuarioService,
    CategoriaBoletosService,
    OperadorTuristicoService,
    PublicacionesServicesService,
    CategoriaBoletosIncluidosService,
    PaisesService,
    TiposBoletosService,
    TipoDocumentoService,
    CuponesService,
    PlanService,
    CambioMonedaService,
    VentasService,
    ObservacionesService,
    FechasAbiertasService,
    DiasSemanaService,
    PreciosService,
    DevolucionService,
];

@Injectable()
export class NbSimpleRoleProvider extends NbRoleProvider {
    constructor(private authService: NbAuthService) {
        super();
    }
  
    getRole(): Observable<string> {
      return this.authService.onTokenChange()
        .pipe(
          map((token: NbAuthJWTToken) => {
            console.log(token.getPayload()['roles']);
            return token.getPayload()['roles'];
          }),
        );
    }
}

export const NB_CORE_PROVIDERS = [
    ...MockDataModule.forRoot().providers,
    ...DATA_SERVICES,
    ...SERVICE_PROVIDER,
    ...NbAuthModule.forRoot({

        strategies: [
            NbPasswordAuthStrategy.setup({
                name: 'email',
                token: {
                    class: NbAuthJWTToken,
                    key: 'token'
                },
                baseEndpoint: `${environment.apiProtocol}://${environment.apiServer}:${environment.apiPort}/${environment.apiPath}/`,
                login: {
                    endpoint: 'login',
                    //requireValidToken: false,
                    redirect: {
                        //success: '/pages/iot-dashboard', // welcome page path
                        failure: null, // stay on the same page
                    },
                },
                register: {
                    endpoint: 'register',
                }
            }),
        ],
        forms: {
            login: {
                socialLinks: socialLinks,
            },
            register: {
                socialLinks: socialLinks,
            },
            logout: {
                redirectDelay: 0,
            },
        },
    }).providers,

    NbSecurityModule.forRoot({
        accessControl: {
            ROLE_ADMIN: {
                view: ['publicaciones', 'pages'],
            },
        },
    }).providers,

    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorFormat, multi: true},
    AnalyticsService,
    LayoutService,
    PlayerService,
    SeoService,
    StateService,
    ToastService,
    SettingsService,
    FormService,
    NotiflixService,
    HowlerService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
