<input type="file" class="btn btn-default btn-file" (change)="onFileSelected($event)" #fileUpload style="display:none;">



<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}}" >
        <div class="file-upload" [nbSpinner]="loading" nbSpinnerSize="small" nbSpinnerStatus="primary" >
            <a [href]="getUri()" target="_blank" class="mr-2" >{{value}}</a>
            <button *ngIf="!value" color="primary" class="btn btn-default btn-file" (click)="fileUpload.click()">
                {{title}} 
            </button>
            <a *ngIf="value" color="primary" class="btn btn-danger btn-sm cursor-pointer" title="Eliminar" (click)="delete()" >
                <i class="fa fa-trash" ></i>
            </a>
        </div>       
        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="getName()"></ui-input-errors>
    </div>
</div>
