<div class="ui-page-offline">
  <div class="ui-page-waiting" >
    <div>
      <h2 class="title">{{title}}</h2>
      <small class="sub-title">{{subtitle}}</small>
      <!--<button nbButton fullWidth (click)="reload()" type="button" class="home-button">
        Volver a cargar
      </button>-->
    </div>
  </div>
</div>