import { ChatVentas } from "./chatventas";

export class ChatVentasList {

    public rooms: Array<ChatVentas>;
    public token: string;

    constructor(token) {
        this.token = token;
        this.rooms = [];
    }

    getRooms(): Array<ChatVentas> {
        return this.rooms;
    }

    getRoom(room: string) : ChatVentas {
        return this.rooms.find(r => r.room == room);
    }

    connectRoom(room: string) : ChatVentas{
        let chat = this.getRoom(room);
        if(!chat){
            chat = new ChatVentas(room, this.token);
            this.rooms.push(chat);
        }
        return chat;
    }

    disconnectRoom(room){
        const index = this.rooms.findIndex(u => u.room == room);
        if(index !== -1){
            return this.rooms.splice(index, 1)[0];
        }
    }


    setRoomsJoined(chat_room_user: Array<any>)  {
        this.rooms.map((chat : ChatVentas) => {
            chat.joined = chat_room_user.findIndex(r => r.chat_room_id == chat.chat.id) != -1;
        });
    }


    getRoomsJoined(flag: boolean = true): Array<any>    {
        if(flag){
            return this.getRooms().filter((r: ChatVentas) => r.joined);
        }else{
            return this.getRooms().filter((r: ChatVentas) => !r.joined);
        }
    }


}
