import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ui-input-quantity',
    templateUrl: "input-quantity.component.html",
    styleUrls: ["input-quantity.component.scss"],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi:true,
        useExisting: UiInputQuantityComponent
      }
    ]
  })
  export class UiInputQuantityComponent implements ControlValueAccessor {
  
    public quantity = 0;
  
    @Input()
    public min: number = 0;
    @Input()
    public max: number = 999999999;
    @Input()
    public label: string = "";
    @Input()
    public increment: number = 1;
    @Input()
    public horizontal: boolean = false;
    @Input()
    public classLabel: string = '';

    classHorizonalContent(){
      return this.horizontal ? 'row': '';
    }
    classHorizonalLabel(){
      return this.horizontal ? 'col-lg-3': '';
    }

    classHorizonalInput(){
      return this.horizontal ? 'col-lg-9': '';
    }

    onChange = (quantity: number) => {};
  
    onTouched = () => {};
  
    touched = false;
  
    disabled = false;
  
    onAdd() {
      this.markAsTouched();
      if (!this.disabled) {
        let quantity = this.quantity + this.increment;
        if(quantity <= this.max){
          this.quantity = quantity;
          this.onChange(this.quantity);
        }        
      }
    }
  
    onRemove() {
      this.markAsTouched();
      if (!this.disabled) {
        let quantity = this.quantity - this.increment;
        if(quantity >= this.min){
          this.quantity = quantity;
          this.onChange(this.quantity);
        }  
      }
    }
  
    writeValue(quantity: number) {
      this.quantity = quantity;
    }
  
    registerOnChange(onChange: any) {
      this.onChange = onChange;
    }
  
    registerOnTouched(onTouched: any) {
      this.onTouched = onTouched;
    }
  
    markAsTouched() {
      if (!this.touched) {
        this.onTouched();
        this.touched = true;
      }
    }
  
    setDisabledState(disabled: boolean) {
      this.disabled = disabled;
    }
  }