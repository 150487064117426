import { LayoutService } from './layout.service';
import { AnalyticsService } from './analytics.service';
import { PlayerService } from './player.service';
import { StateService } from './state.service';
import { SeoService } from './seo.service';
import { ToastService } from './toast.service';
import { SettingsService } from './settings.service';
import { FormService } from './form.service';
import { NotiflixService } from './notiflix.service';
import { HowlerService } from './howler.service';

export {
  LayoutService,
  AnalyticsService,
  PlayerService,
  SeoService,
  StateService,
  ToastService,
  SettingsService,
  FormService,
  NotiflixService,
  HowlerService
};
