import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class SubirDocumentoService {

  constructor(public api: ApiService){

  }

  upload<T>(data): Observable<any> {
    return this.api.formData('documentos/subir', data);
  }

}
