<nb-layout windowMode>

  <nb-layout-column>

    <div class="ui-page-waiting"  [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary">

      <div>
        <h2 class="title">{{title}}</h2>
        <small class="sub-title">{{subtitle}}</small>

        <div class="ui-page-waiting-loading">          
           <!--<nb-icon *ngIf="loading" class="ui-page-waiting-checkin" icon="checkmark-circle" nbIconSize="large"></nb-icon>-->
        </div>

        <!--<button nbButton fullWidth (click)="goToHome()" type="button" class="home-button">
          Take me home
        </button>-->

      </div>


    </div>

  </nb-layout-column>

</nb-layout>