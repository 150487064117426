<input #inputRef type="file" (change)="fileChangeEvent($event)" style="display: none" />
<div [nbSpinner]="loading" nbSpinnerSize="small" nbSpinnerStatus="primary">
    <div class="row">
        <div class="col-lg-9">
            <div *ngIf="!uploadPermisible()" class="ui-input-image-crop-dialod-content" >
                <div class="ui-input-image-crop-dialog-content-item">
                    <p style="color: red;">
                        Dimensiones mínimas {{minWidth}} x {{minHeight}}
                    </p>

                    <button class="btn btn-info" (click)="btnOpenWindow()">
                        <i class="fa fa-folder mr-2"></i> Buscar imagen
                    </button>
                </div>
            </div>
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio"
                    [cropperMinWidth]="minWidth" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            
        </div>
        <div class="col-lg-3">
            <div class="form-group">
                <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" style="width:100%" />

                {{imageInfo}}
            </div>

            <div class="form-group " style="display:none">
                <label for="titulo" class="label col-form-label">Título</label>
                <input id="ui-input-image-crop-dialog" class="form-control" />
            </div>

            <div class="form-group">
                <button class="btn btn-default btn-sm" (click)="rotateLeft()">+90°</button>
                <button class="btn btn-default btn-sm" (click)="rotateRight()">-90°</button>
                <button class="btn btn-default btn-sm" (click)="flipHorizontal()"><i class="fa fa-arrow-left"></i><i
                        class="fa fa-arrow-right"></i></button>
                <button class="btn btn-default btn-sm" (click)="flipVertical()"><i
                        class="fa fa-arrow-down"></i></button>
            </div>
            <div class="form-group">
                <button class="btn btn-default btn-sm"
                    (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain
                    Within Aspect Ratio'}}</button>
            </div>
            <div class="form-group">
                <button class="btn btn-default btn-sm" (click)="resetImage()">Reset image</button>
            </div>
            <div class="form-group">
                <button class="btn btn-default btn-sm" (click)="rotationHorario()">+H</button>
                <button class="btn btn-default btn-sm" (click)="rotationAntihorario()">-H</button>

                <button class="btn btn-default btn-sm" (click)="zoomOut()">Zoom -</button>
                <button class="btn btn-default btn-sm" (click)="zoomIn()">Zoom +</button>
            </div>

            <div class="form-group">
                <button *ngIf="imageChangedEvent" class="btn btn-info mr-1" (click)="btnOpenWindow()">
                    <i class="fa fa-folder mr-2"></i> Buscar
                </button>
                <button *ngIf="imageChangedEvent" class="btn btn-primary mr-1" (click)="upload()" [disabled]="!uploadPermisible()" >
                    Guardar
                </button>
                <button class="btn btn-default mr-1" mat-dialog-close cdkFocusInitial >
                    Cancelar
                </button>                
                
            </div>


        </div>
    </div>

</div>