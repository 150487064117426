import { Component, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'ui-input-html',
  templateUrl: "input-html.component.html",
  styleUrls: ["input-html.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputHtmlComponent
    }
  ]
})
export class UiInputHtmlComponent implements ControlValueAccessor {

  @Input() formControlName: string;
  @Input() name: string = null;
  @Input() inline: boolean = false;
  @Input() type: string = 'text';
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = '';

  ckeConfig: any;
  value: any;
  touched = false;
  disabled = false;

  constructor(public _dialog: MatDialog) {

  }

  ngOnInit() {

    this.ckeConfig = {
      toolbar: {
        items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList',
          'numberedList', '|', 'insertTable', '|', 'undo', 'redo', 'imageUpload',
          ' classicEditor', 'blockQuote', 'list', 'mediaEmbed', 'pasteFromOffice',
          'fontFamily', 'todoList', 'youtube'
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
    };
  }

  classHorizonalContent() {
    return this.horizontal ? 'row' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput() {
    if (this.displayErrors()) {
      return `${this.classInput} ui-input-error`;

    }
    return this.classInput;
  }

  openDialog() {
    const dialogRef = this._dialog.open(UiInputHtmlDialogComponent, {
      width: '100vw',
      data: { value: this.value, label: this.label },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.value = result.value;
        this.onChange(this.value);
        this.markAsTouched();
      }
    });
  }

  displayErrors(): boolean {
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if ((this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
      return true;
    }
    return false;
  }

  getName() {
    if (this.name) {
      return this.name;
    } else if (this.formControlName) {
      return this.formControlName
    }
    return `INPUT${Math.max(0, 99999)}`;
  }

  change(e) {
    this.value = e.editor._.data;
    //console.log("INPUT: ", this.value);
    this.onChange(this.value);
    this.markAsTouched();
  }

  onChange = (quantity: string) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}

@Component({
  selector: 'ui-input-html-dialog',
  template: `
      <div *ngIf="label" mat-dialog-title>
          {{label}}
      </div>
      <mat-dialog-content class="mat-typography" style="max-height: 100%;color:#000;">
        <ckeditor  [config]="{extraPlugins: 'divarea', height: '320' }" [value]="value" (blur)="change($event)"  ></ckeditor>
      </mat-dialog-content>
      <mat-dialog-actions>
          <button class="btn btn-default btn-default mr-2" mat-dialog-close cdkFocusInitial >Cancelar</button>
          <button class="btn btn-default btn-primary" (click)="selectedChange()"  >Guardar</button>
      </mat-dialog-actions>    
  `,
})
export class UiInputHtmlDialogComponent {

  public value: string;
  public label: string;

  public constructor(
    public _dialogRef: MatDialogRef<UiInputHtmlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    this.value = dataDialog?.value;
    this.label = dataDialog?.label;
  }

  change(e): void {
    this.value = e.editor._.data;  
  }

  selectedChange(): void {
    setTimeout(() => {
      this._dialogRef.close({ value: this.value });
    }, 300);
  }
}
