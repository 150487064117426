import { Component, OnInit, Input, SimpleChanges, OnChanges, ElementRef, ViewChild, Output, EventEmitter, Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { InputImageCropDialogComponent } from './input-image-crop-dialog.component';

@Directive({
    selector: '[input-image-crop]'
})
export class InputImageCropDirective implements OnChanges {

    @Input('filename') filename: string;
    @Input('titulo') titulo: string = "";
    @Input('type') type: string;
    @Input() secure: boolean = false;
    @Input('size') size: string = "";
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
    public istemp: boolean = false;
    public loading: boolean = false;

    public openWindow: boolean = true;

    @ViewChild('inputRef', { static: true }) inputRef: ElementRef;

    constructor(
        public _dialog: MatDialog,
    ) {

    }    

    @HostListener('click', ['$event']) onClick($event){

        this.openDialog($event);
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.filename?.currentValue != changes.filename?.previousValue){
            this.filename = changes.filename.currentValue;
        }
    }

    clickImage(){
        this.inputRef.nativeElement.click();
    }

    openDialog(event: any) {        
        const dataSend =  { imageChangedEvent: event, type: this.type, titulo: this.titulo, openWindow: this.openWindow, secure: this.secure};
        //console.log(dataSend);
        const dialogRef = this._dialog.open(InputImageCropDialogComponent, {
            width: '100vw',
            height: '95vh',
            data: dataSend,
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if(result.filename){
                    this.filename = result.filename;
                    this.titulo = result.titulo;
                    this.istemp = true;
                    const dat = {
                        filename: this.filename,
                        titulo: this.titulo
                    };
                    this.onChange.emit(dat);
                }else{

                }
            }
            this.loading = false;
        });
    }

    fileChangeEvent(event: any): void {
        this.openDialog(event);
        //this.imageChangedEvent = event;
    }

    

}
