import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class RegistrosService {

    public $REGISTROS_SALA_VENTA = 18;
    public $REGISTROS_TIPO_VENTA = 7;
    public $REGISTROS_IDIOMAS = 17;
    public $REGISTROS_CAMPANYA = 19;
    public $REGISTROS_ENVIAR_CORREO = 23;
    public $REGISTROS_PLATAFORMA_RESERVA = 24;
    public $REGISTROS_ESTADO_PAGO = 1;
    public $REGISTROS_ESTADO_COMPRA = 55;
    public $REGISTROS_TIPO_BOLETOS = 61;
    public $REGISTROS_MENSAJE_CHAT = 61;

    public $dataSalaVenta: Array<any>;
    public $dataTipoVenta: Array<any>;
    public $dataIdiomas: Array<any>;
    public $dataCampanya: Array<any>;
    public $dataEnviarCorreo: Array<any>;
    public $dataPlataformaReserva: Array<any>;
    public $dataEstadoPago: Array<any>;
    public $dataEstadoCompra: Array<any>;
    public $dataMensajeChat: Array<any>;

    constructor(public api: ApiService) {
    }

    findAll<T>(params = null): Observable<any> {
        return this.api.get('registros', params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`registros/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('registros/add/', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('registros/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`ubigeo/delete/${id}`);
    }

    findBy<T>(params: any = null): Observable<any> {
        return new Observable((observer) => {
            this.api.get('registros', params).subscribe((r) => {

                this.asignacionDataRegistro(params, r.data);

                return observer.next(r);
            }, (err) => {
                observer.error(err);
            });
        });
    }

    private asignacionDataRegistro(params, data) {
        if (params.id === this.$REGISTROS_SALA_VENTA) {
            this.$dataSalaVenta = data;
        }

        if (params.id === this.$REGISTROS_TIPO_VENTA) {
            this.$dataTipoVenta = data;
        }

        if (params.id === this.$REGISTROS_IDIOMAS) {
            this.$dataIdiomas = data;
        }

        if (params.id === this.$REGISTROS_CAMPANYA) {
            this.$dataCampanya = data;
        }

        if (params.id === this.$REGISTROS_ENVIAR_CORREO) {
            this.$dataEnviarCorreo = data;
        }

        if (params.id === this.$REGISTROS_PLATAFORMA_RESERVA) {
            this.$dataPlataformaReserva = data;
        }

        if (params.id === this.$REGISTROS_ESTADO_COMPRA) {
            this.$dataEstadoCompra = data;
        }

        if (params.id === this.$REGISTROS_ESTADO_PAGO) {
            this.$dataEstadoPago = data;
        }

        if (params.id === this.$REGISTROS_MENSAJE_CHAT) {
            this.$dataMensajeChat = data;
        }
    }
}
