import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {SocketChatAdapter} from './socketchat.adapter';
import {ChatVentas} from './chatventas';
import {ChatVentasList} from './chatventasList';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ChatVentasService {

    public review: boolean = false;
    public user;
    public token;
    public rooms: ChatVentasList;
    public type: string = 'ventas';
    public serverSocket: string = environment.serverSocket;
    public headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private socket: SocketChatAdapter, private authService: NbAuthService, public http: HttpClient) {

        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
                this.token = this.user.idToken;
                this.rooms = new ChatVentasList(this.token);
            }
        });

    }

    setReview(review: boolean) {
        this.review = review;
    }

    joinRoom(room: string) {
        this.socket.emit('joinRoom', {token: this.token, room, type: this.type});
    }
    joinAdminRoom() {
        this.socket.emit('admin/joinRoom', {type: this.type});
    }
    sendMessage(msg: any, admin: number = 0) {
        this.socket.emit('chatMessage', {msg, admin});
    }
    getMessageList(ultimate) {
        return this.socket.emit('messages', {ultimate});
    }
    setUserJoinedRoom({user, chat_room, admin = 0}) {
        return this.socket.emit('userjoined', {user, chat_room, admin});
    }

    setUserUnjoinedRoom({user, chat_room}) {
        return this.socket.emit('userunjoined', {user, chat_room});
    }

    setUserDeriveRoom({token, new_user_id, chat_room}) {
        return this.socket.emit('userDerive', {token, new_user_id, chat_room});
    }

    setUserConectedRooms({rooms = []}) {
        return this.socket.emit('usersconnected.rooms', {rooms});
    }

    setRoomsOnline({type}) {
        return this.socket.emit('roomsOnline', {type});
    }
    exitSalonBySocketId() {
        return this.socket.emit('exitRoom');
    }
    allViewCheck(chat_room_id: any, room: any) {
        return this.socket.emit('message.view.allcheck', {chat_room_id, room});
    }
    viewCheckSalesbook(token: string) {
        return this.socket.emit('notifications:checkSalesbook', {token});
    }


    /*
        .on Listen node
    */

    onJoinRoom() {
        return this.socket.fromEvent('joinRoom');
    }

    onMessage() {
        return this.socket.fromEvent('message');
    }

    onMessageAdmin() {
        return this.socket.fromEvent('message.admin');
    }

    onMessageList() {
        return this.socket.fromEvent('messages');
    }

    onUserJoinedRoom() {
        return this.socket.fromEvent('userjoined');
    }

    onUserUnjoinedRoom() {
        return this.socket.fromEvent('userunjoined');
    }

    onUserDeriveRoom() {
        return this.socket.fromEvent('userDerive');
    }

    onUserJoinedRoomAdmin() {
        return this.socket.fromEvent('userjoined.admin');
    }

    onUserUnjoinedRoomAdmin() {
        return this.socket.fromEvent('userunjoined.admin');
    }

    onRoomJoined() {
        return this.socket.fromEvent('joined');
    }

    onRoomJoinedAdmin() {
        return this.socket.fromEvent('joined.admin');
    }

    onRoomsOnline() {
        return this.socket.fromEvent('roomsOnline');
    }

    onConnected() {
        return this.socket.fromEvent('connected');
    }

    onDisconnectUser() {
        return this.socket.fromEvent('disconnect.user');
    }

    onDisconect() {
        return this.socket.fromEvent('disconnect.user');
    }

    onDisconectAdmin() {
        return this.socket.fromEvent('disconnect.admin');
    }

    onUserConectedRooms() {
        return this.socket.fromEvent('usersconnected.rooms');
    }

    onMessageViewCount() {
        return this.socket.fromEvent('message.view.count');
    }

    onNotificationSaled() {
        return this.socket.fromEvent('notifications:viewSaled');
    }

    onNotificationRepayment() {
        return this.socket.fromEvent('notifications:viewRepayment');
    }

    onNotificationSaledUpdate() {
        return this.socket.fromEvent('notifications:viewSaled:update');
    }

    onNotificationRepaymentUpdate() {
        return this.socket.fromEvent('notifications:viewRepayment:update');
    }

    onMessageViewCountChat() {
        return this.socket.fromEvent('message:view-count');
    }

    onMessageViewPlusChat() {
        return this.socket.fromEvent('message:view-plus');
    }




}
