import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class PaisesService {

    public $data: Array<any>;

    constructor(public api: ApiService) {
    }

    findAll<T>(params = null): Observable<any> {
        return new Observable((observer) => {
            this.api.get('paises', params).subscribe((r) => {
                this.$data = r.data;
                return observer.next(r);
            }, (err) => {
                observer.error(err);
            });
        });
    }
}
