import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Injectable()
export class FormService {

    validateAllFormFields(formGroup: FormGroup | FormArray) {              
        let list = formGroup instanceof FormGroup ? Object.keys(formGroup.controls) : formGroup.controls;
        if(formGroup instanceof FormGroup){
            formGroup.markAsTouched({ onlySelf: true });
            Object.keys(formGroup.controls).forEach(field => {      
                const control = formGroup.get(field);               
                if (control instanceof FormControl) {               
                    control.markAsTouched({ onlySelf: true });
                } else if (control instanceof FormGroup || control instanceof FormArray) {
                    this.validateAllFormFields(control);
                }
            });
        }else if (formGroup instanceof FormArray) {
            formGroup.markAsTouched({ onlySelf: true });
            formGroup.controls.forEach(control => {                 
                if (control instanceof FormControl) {               
                    control.markAsTouched({ onlySelf: true });
                } else if (control instanceof FormGroup || control instanceof FormArray) {
                    this.validateAllFormFields(control);
                }
            });
        }
        
    }
}
