import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-form-card',
  templateUrl: "form-card.component.html",
  styleUrls: ["form-card.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiFormCardComponent
    }
  ]
})
export class UiFormCardComponent implements ControlValueAccessor, OnChanges {

  @Input() role: string = "";
  @Input('name') name: string;
  @Input('formArrayName') formArrayName: string;
  @Input('formGroupName') formGroupName: string;
  @Input('formControlName') formControlName: string;
  @Input() form: FormArray | FormGroup | FormControl;
  @Input() formControl: FormArray | FormGroup | FormControl;

  value: any;
  touched: boolean = false;
  disabled: boolean = true;

  displayErrors(): boolean {

    
    if (this.formControl && (this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form.currentValue != changes.form.previousValue) {
      this.getFormControl();
    }
  }


  getFormControl(): void {

    if (this.form) {

      if (this.formControlName) {
        this.formControl = this.form.get(this.formControlName) as FormControl;
      } else if (this.formGroupName) {
        this.formControl = this.form.get(this.formGroupName) as FormGroup;
      } else if (this.formArrayName) {
        this.formControl = this.form.get(this.formArrayName) as FormArray;
      } else {
        this.formControl = this.form.get(this.name) as FormArray | FormGroup | FormControl;
      }

      this.writeValue(this.formControl.value);
    }
  }

  getName(): string {
    if (this.formControlName) {
      return this.formControlName;
    } else if (this.formGroupName) {
      return this.formGroupName;
    } else if (this.formArrayName) {
      return this.formArrayName;
    }
    return this.name || 'name'
  }


  onChange = (quantity: any) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}