<div mat-dialog-title>
    <div class="form-group" style="position:relative;">
        <div class="input-group">
            
            <input #inputRef type="text" id="input-search" class="form-control" (keyup.enter)="search(address)"
                placeholder="Address">
            <div class="input-group-btn">
                <button type="button" class="btn btn-fab btn-success btn-fab-mini" title="search"
                    (click)="getCurrentPosition()">
                    <span class="fa fa-search" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography" style="max-height: 100%;">

    <google-map [center]="center" [zoom]="zoom" width="100%"  >
        <map-marker [position]="center" [options]="markerOptions" (mapDragend)="move($event)"></map-marker>
    </google-map>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="btn btn-default btn-default mr-2" mat-dialog-close cdkFocusInitial>Cancelar</button>
    <button class="btn btn-default btn-primary" (click)="selectedChange()"  >Seleccionar</button>
</mat-dialog-actions>