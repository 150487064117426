import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class DevolucionService {

    constructor(public api: ApiService) {

    }

    find<T>(id: string): Observable<any> {
        return this.api.get(`devolucion/${id}`, null);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('devolucion/update/', data);
    }
}
