import { HttpClient, HttpHandler, HttpHeaders } from "@angular/common/http";
import { UiFormCardComponent } from "../../@theme/components";
import { environment } from '../../../environments/environment';

export class ChatVentas {

    public token: string;
    public room: string;
    public chat: any;
    public message: any;
    public joined: boolean;
    public messages: Array<any> = [];
    public users: Array<any> = [];
    public online: boolean = false;
    public newMessage: boolean = false;
    public headers = new HttpHeaders({'Content-Type': 'application/json'});
    public serverSocket:string = environment.serverSocket;
    public count_view: number = 0;

    public http: HttpClient;

    constructor(room: string, token: string) {
        this.token = token;
        this.room = room;
        this.http = new HttpClient(null);
    }

    aaa() {
        return this.users;
    }

    setChat(chat){
        this.chat = chat;
        this.count_view = chat.chat ? chat.chat.length : 0;
    }

    // settear cantidad vi vistas
    setView(views){
        this.count_view = views;
    }

    setMessages(messages: Array<any>) {
        this.messages = messages;
    }

    addMessages(messages: Array<any>) {
        messages.forEach(m => {
            this.messages.push(m);
        });
    }

    isOnline(): boolean {
        return this.online;
    }

    otherOnline(token){
        return this.users.findIndex(u => u.user.token !== token) != -1 ;
    }

    getUserClient() {
        return this.users.find(u => !u.admin);
    }

    getUser(user_id) {
        return this.users.find(u => u.user.id === user_id);
    }

    connect(user) {
        console.log("CONNECT", user, this.token);
        if(user.user){
            const u = this.getUser(user.user.id);
            if (!u) {
                this.online = this.token != user.user.token;
                this.users.push(user);
            }
        }
    }

    disconnect(user) {
        console.log(user, this.users);
        const index = this.users.findIndex(u => u.user.id === user.user.id);
        if(index !== -1){
            const u = this.users.splice(index, 1)[0];
            this.online = this.users.findIndex(u => u.user && u.user.token !== this.token) != -1;
            return u;
        }
    }

    addMessage(message: any) {
        this.message = message;
        this.messages.push(message);
    }

    getMesageText(){
        return this.message ? this.message.text : '';
    }

    getMessages(): Array<any> {
        return this.messages.sort((a, b) => b.id - a.id);
    }

    lastMessage() {
        const message = this.getMessages();
        if (message.length > 0) {
            return message[message.length - 1];
        }
        return null;
    }

    loadMessages(){
        if(this.messages.length > 0){
          const m = this.messages[0];
          this.http.post(`${this.serverSocket}/rooms/${this.room}/messages`, {ultimate: m.id}, { headers: this.headers, responseType: 'json' }).toPromise().then((res: any) => {
            if(Array.isArray(res.data)){
              res.data.forEach((m) => {
                this.messages.push(m);
              });
            }
          });
          //this.chatVentasService.getMessageList(m.id);
        }
    }


}
