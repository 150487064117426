import { Component, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-input-image',
  templateUrl: "input-image.component.html",
  styleUrls: ["input-image.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputImageComponent
    }
  ]
})
export class UiInputImageComponent implements ControlValueAccessor {
  
  @Input() formControlName: string;
  @Input() title: string = null;
  @Input() name: string = null;
  @Input() inline: boolean = false;
  @Input() secure: boolean = false;
  @Input() type: string;
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormArray;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;  
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = '';
  @Input() temporal: boolean = false;

  @Input() width: string = "auto";
  @Input() height: string = '10rem';
  @Input() style: string = '';
  @Input('classContainer') classContainer: string = 'form-group';

  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  imagensNews = [];

  value: any;

  touched = false;

  disabled = false;

  newfilename = null;

  classHorizonalContent() {
    return this.horizontal ? 'row ' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput(){
    if(this.displayErrors()){
      return `${this.classInput} ui-input-error`;

    }
    return this.classInput;
  }

  displayErrors(): boolean {
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if((this.formControl?.touched || this.formControl?.dirty)&& this.formControl?.invalid){
      return true;
    }
    return false;
  }

  getName(){
    if(this.name){
      return this.name;
    }else if(this.formControlName){
      return this.formControlName
    }
    return `INPUT${Math.max(0, 99999)}`;
  }

  removeImagen(){
    //this.writeValue(null);
    this.value = undefined;
    this.onChange(undefined);
    this.onRemove.emit();
  }

  addImagen(e){
    this.newfilename = e.filename;
    this.value = e.filename;
    //this.writeValue(this.newfilename);
    this.onChange(this.value);
  }

  isNew(): boolean{
    return this.newfilename != null || this.temporal as boolean;
  }

  onChange = (value: any) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {      
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}