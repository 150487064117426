import {Component, Inject, Input, OnInit} from '@angular/core';
import { prettyPrintJson, FormatOptions } from 'pretty-print-json';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'ngx-pretty-json',
    templateUrl: 'pretty-json.component.html',
    styleUrls: ['pretty-json.component.scss'],
})
export class PrettyJsonComponent implements OnInit {

    public dataRaw: any;
    public dataWebhook: any;

    public constructor( @Inject(MAT_DIALOG_DATA) public dataDialog: any ) {
        this.dataRaw = dataDialog?.valueRaw;
        this.dataWebhook = dataDialog?.valueWebhook;
    }

    ngOnInit() {
        this.cargarData();
    }

    cargarData() {
        const options: FormatOptions = { linkUrls: true, lineNumbers: true, indent: 20, quoteKeys: true };
        const elementoRaw = document.getElementById('dataRaw');
        elementoRaw.innerHTML = prettyPrintJson.toHtml(this.dataRaw, options);
        const elementoWebhook = document.getElementById('dataWebhook');
        elementoWebhook.innerHTML = prettyPrintJson.toHtml(this.dataWebhook, options);
    }

}
