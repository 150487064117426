import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from'ngx-infinite-scroll';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbSpinnerModule,
  NbCardModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GoogleMapsModule } from '@angular/google-maps';

import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
  ImageComponent,
  InputImageCropComponent,
  InputImageCropDialogComponent,
  UiInputComponent,
  UiInputSelectComponent,
  UiInputHtmlComponent,
  UiInputHtmlDialogComponent,
  UiInputStatusComponent,
  UiInputGeolocationComponent,
  UiFormCardComponent,
  MapPositionComponent,
  MapDialogComponent,
  UiInputFileComponent,
  UiInputImageComponent,
  UiInputImageListComponent,
  InputImageCropDirective,
  UiInputQuantityComponent,
  UiInputErrorsComponent,
  CarouselComponent
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  WaitingComponent,
  OfflineComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
// import { InputImageCropDialogComponent } from './components/input-image-crop/input-image-crop-dialog.component';

import { MapService } from './components/map-position/map.service';
import { GeolocationService } from './components/map-position/geolocation.service';
import { GeocodingService } from './components/map-position/geocoding.service';

import { NgxFileDropModule } from 'ngx-file-drop';
import { SubirDocumentoService } from '../@core/api/subir-documentos';
import { CKEditorModule } from 'ng2-ckeditor';
import { NbPopoverModule } from '@nebular/theme';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwipeSliderComponent } from './components/swipe-slider/swipe-slider.component';

const MAT_MODULES = [
  MatDialogModule,
  NgxFileDropModule,
  MatSlideToggleModule,
  CKEditorModule,
  MatCheckboxModule,
  MatSelectModule,
];

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbSpinnerModule,
  NbPopoverModule,
  NbCardModule,
  ImageCropperModule,
  GoogleMapsModule,
  NgSelectModule,
  CarouselModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  ImageComponent,
  UiInputSelectComponent,
  MapPositionComponent,
  InputImageCropDirective,
  InputImageCropComponent,
  InputImageCropDialogComponent,
  UiInputComponent,
  UiInputHtmlComponent,
  UiInputHtmlDialogComponent,
  UiInputStatusComponent,
  UiInputGeolocationComponent,
  UiInputFileComponent,
  UiInputImageComponent,
  UiInputImageListComponent,
  UiInputQuantityComponent,
  UiInputErrorsComponent,
  UiFormCardComponent,
  MapDialogComponent,
  SwipeSliderComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  WaitingComponent,
  OfflineComponent,
  CarouselComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, InfiniteScrollModule, ...NB_MODULES, ...MAT_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
  providers: [
    MapService,
    GeolocationService,
    GeocodingService,
    SubirDocumentoService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'dark',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
