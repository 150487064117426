import { Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { SubirDocumentoService } from '../../../@core/api/subir-documentos';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
export const ROUTE_FILE = `${environment.routeUploads}`;
export const ROUTE_FILE_TEMP = `${environment.routeUploadsTemp}`;

@Component({
    selector: 'ui-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: UiInputFileComponent
        }
    ]
})
export class UiInputFileComponent implements ControlValueAccessor {

    @Input() formControlName: string;
    @Input() name: string;
    @Input() label: string;
    @Input() title: string = "Subir archivo";
    @Input() type: string = "itinerario";
    @Input() horizontal: boolean = false;
    @Input() classLabel: string = '';
    @Input() classInput: string = 'ui-input-file-bottom';
    @Input() formGroup: FormGroup;
    @Input() formControl: FormControl;

    public loading: boolean = false;

    value: string;
    touched = false;
    disabled = false;
    pristine = true;

    constructor(public _subirDocumento: SubirDocumentoService) {

    }
    classHorizonalContent() {
        return this.horizontal ? 'row' : '';
    }
    classHorizonalLabel() {
        return this.horizontal ? 'col-lg-3' : '';
    }

    classHorizonalInput() {
        return this.horizontal ? 'col-lg-9' : '';
    }
    getClassInput() {
        if (this.displayErrors()) {
            return `${this.classInput} ui-input-error`;
        }
        return `${this.classInput}`;
    }

    getUri() {
        return this.pristine ? `${ROUTE_FILE}documentos/${this.type}/${this.value}` : `${ROUTE_FILE_TEMP}${this.value}`;
    }

    onFileSelected(event) {

        const file: File = event.target.files[0];

        if (file) {
            this.loading = true; 
            let formData: any = new FormData();
            formData.append("tipo_documento", this.type);
            formData.append("archivo", file);
            this._subirDocumento.upload(formData).subscribe(upload => {
                this.loading = false;
                const { data: { nombre } } = upload;
                this.change(nombre);
                this.pristine = false;
            }, e => {
                this.loading = false;
            });
        }
    }

    displayErrors(): boolean {
        if (this.formGroup) {
            this.formControl = this.formGroup.get(this.formControlName) as FormControl;
        }
        if ((this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
            return true;
        }
        return false;
    }

    getName() {
        if (this.name) {
            return this.name;
        } else if (this.formControlName) {
            return this.formControlName
        }
        return `INPUT${Math.max(0, 99999)}`;
    }

    change(e) {
        this.value = e;
        //console.log("INPUT: ", this.value);
        this.onChange(this.value);
        this.markAsTouched();
    }

    onChange = (value: string) => { };

    onTouched = () => { };

    writeValue(value: any) {
        this.value = value;
    }

    delete(){
        this.change(undefined);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}