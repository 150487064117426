import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class CategoriaBoletosIncluidosService {

    constructor(public api: ApiService) {

    }

    findAll<T>(params: any = null): Observable<any> {
        const {publicacion_id} = params;
        return this.api.get(`categoria-boletos-incluidos/list/${publicacion_id}`, params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`categoria-boletos-incluidos/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('categoria-boletos-incluidos/add', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('categoria-boletos-incluidos/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`categoria-boletos-incluidos/delete/${id}`);
    }

    findAllInclude<T>(params: any = null): Observable<any> {
        const {publicacion_id} = params;
        return this.api.get(`categoria-boletos-incluidos/list-incluidos/${publicacion_id}`, params);
    }

    updateInclude<T>(data: any): Observable<any> {
        return this.api.post('categoria-boletos-incluidos/edit-included/', data);
    }
}
