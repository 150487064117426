import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-input',
  templateUrl: "input.component.html",
  styleUrls: ["input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiInputComponent
    }
  ]
})
export class UiInputComponent implements ControlValueAccessor {
  
  @Input() formControlName: string;  
  @Input() autocomplete: string;
  @Input() name: string = null;  
  @Input() title: string = null;  
  @Input() disabled: boolean = false;
  @Input() variant: string = 'input';
  @Input() rows: number = 3;
  @Input() inline: boolean = false;
  @Input() type: string = 'text';
  @Input() min: number = 0;
  @Input() max: number = 999999999;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl = new FormControl();
  @Input() errors: any;  
  @Input() horizontal: boolean = false;
  @Input() classLabel: string = '';
  @Input() classInput: string = 'form-control';
  @Input('classContainer') classContainer: string = 'form-group';

  value: any;
  touched = false;

  classHorizonalContent() {
    return this.horizontal ? 'row' : '';
  }
  classHorizonalLabel() {
    return this.horizontal ? 'col-lg-3' : '';
  }

  classHorizonalInput() {
    return this.horizontal ? 'col-lg-9' : '';
  }
  getClassInput(){
    if(this.displayErrors()){
      return `${this.classInput} ui-input-error`;

    }
    return this.classInput;
  }

  displayErrors(): boolean {
    if(this.formGroup){
      this.formControl = this.formGroup.get(this.formControlName) as FormControl;
    }
    if((this.formControl?.touched || this.formControl?.dirty)&& this.formControl?.invalid){
      return true;
    }
    return false;
  }

  getName(){
    if(this.label){
      return this.label
    }else if(this.title){
      return this.title;
    }else if(this.name){
      return this.name;
    }else if(this.formControlName){
      return this.formControlName
    }
    return `INPUT${Math.max(0, 999)}`;
  }

  change(e) {
    //console.log("INPUT: ",this.formControl);
    this.value = e.target.value;
    this.onChange(e.target?.value);
  }

  onChange = (quantity: number) => { };

  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {      
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}