import { NbMenuService } from '@nebular/theme';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-page-waiting',
  styleUrls: ['./waiting.component.scss'],
  templateUrl: './waiting.component.html',
})
export class WaitingComponent {

  @Input() title: string = 'Cargando...';
  @Input() subtitle: string = '';

  constructor() {
    
  }

  goToHome() {

  }
}
