import { NbMenuService } from '@nebular/theme';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-page-offline',
  styleUrls: ['./offline.component.scss'],
  templateUrl: './offline.component.html',
})
export class OfflineComponent {

  @Input() title: string = 'Sin Conexión a Internet';
  @Input() subtitle: string = '';

  constructor(private _router: Router) {
    
  }

  reload() {
    
  }
}
