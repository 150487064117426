import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class TipoBeneficioService {

  public $data: Array<any>;

  constructor(public api: ApiService){

  }

  findAll<T>(params:any = null): Observable<any> {
    return new Observable((observer) => {
      this.api.get('tipo-beneficio', params).subscribe((r) => {
        this.$data = r.data;
        return observer.next(r);
      }, (err) => {
        observer.error(err);
      });
    });
  }
/*
  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`tipo-beneficio/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('tipo-beneficio/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('tipo-beneficio/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`tipo-beneficio/delete/${id}`);
  }
  */
}
