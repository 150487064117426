<div class="row">
    <div class="col-md-6">
        <div class="m-3">
            <h3 style="color: #1b1b38">DATA RAW</h3>
        </div>
        <div class="dark-mode">
            <div id="dataRaw" class="json-container">
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="m-3">
            <h3 style="color: #1b1b38">DATA WEBHOOK</h3>
        </div>
        <div class="dark-mode">
            <div id="dataWebhook" class="json-container">
            </div>
        </div>
    </div>
</div>
