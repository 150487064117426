import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';

export const BASE_URL_API = `${environment.serverSocket}`;


@Injectable({providedIn: 'root'})
export class ApiChatService {
    public endpoint = '/';
    public token = '';
    public genericError = '';

    constructor(
        public http: HttpClient,
        public authService: NbAuthService,
    ) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid()) {
                    this.token = token.getValue();
                }
            });
    }

    public getToken() {
        return this.token;
    }

    public generateHeaders(params?: any): HttpHeaders {
        let headers = null;
        if (this.token) {
            headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token});
        } else {
            headers = new HttpHeaders({'Content-Type': 'application/json'});
        }
        return headers;
    }

    public generateHeadersUpload(): HttpHeaders {
        let headers;
        headers = new HttpHeaders({'Authorization': 'Bearer ' + this.token});
        return headers;
    }

    public getBaseUrl(path): string {
        return `${BASE_URL_API}/${path}`;
    }

    get<T>(url: any, params?: any): Observable<any> {
        const headers: HttpHeaders = this.generateHeaders(params);
        return this.http.get<T[]>(this.getBaseUrl(url), {headers: headers, params: params});
    }

    post(url: any, object: any, type?: any): Observable<any> {
        const headers = this.generateHeaders(type);
        // const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.getBaseUrl(url), object, {headers: headers, responseType: 'json'});
    }

    patch(url, data: any, accessToken: string = null): Observable<any> {
        const headers = this.generateHeaders();
        return this.http.patch(this.getBaseUrl(url), data, {headers: headers});
    }

    put(url: any, object: any, type?: any): Observable<any> {
        const headers = this.generateHeaders(type);
        return this.http.put(this.getBaseUrl(url), object, {headers: headers, responseType: type});
    }

    delete(url: any, type?: any): Observable<any> {
        const headers = this.generateHeaders(type);
        return this.http.delete(this.getBaseUrl(url), {headers: headers, responseType: type});
    }

    generate(url: any, object: any): Observable<any> {
        const headers = this.generateHeaders();
        return this.http.post(this.getBaseUrl(url), object, {headers: headers, responseType: 'blob'});
    }

    request(req: any): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).access_token;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        return this.http.request(req);
    }

    formData(url: any, data: FormData): Observable<any> {
        const headers = new HttpHeaders({'Authorization': this.token, 'Accept': 'multipart/form-data'});
        return this.http.post(this.getBaseUrl(url), data, {headers: headers});
    }

    postUpload(url: any, object: any, type?: any): Observable<any> {
        const headers = this.generateHeadersUpload();
        // const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.getBaseUrl(url), object, {headers: headers, responseType: 'json'});
    }
}
