<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="name" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <nav aria-label="" class="{{classHorizonalInput()}}">
        <ul class="pagination">
            <li class="page-item"><button class="page-link" (click)="onRemove()"><i
                        class="fa fa-minus"></i></button></li>
            <li class="page-item">
                <div class="page-link no-link">
                    {{quantity || '0'}}
                </div>
            </li>
            <li class="page-item"><button class="page-link" (click)="onAdd()"><i class="fa fa-plus"></i></button>
            </li>
        </ul>
    </nav>
</div>