import { Injectable } from '@angular/core';
import Notiflix from 'notiflix';

@Injectable()
export class NotiflixService {

  config: any;

  constructor() {

    this.config = {
        showOnlyTheLastOne: true,
        useFontAwesome: true,
    }

  }

  /**
   * Configuracion para las notificaciones chat
   */
  configChat(): void {
    this.config = {
        className: "notiflix-notify mt-5",
        info: {
            // childClassName: 'notiflix-notify-info',
            background: '#3B58B0',
            fontAwesomeClassName: 'far fa-comment-dots parpadea-primary',
            fontAwesomeIconColor: 'rgba(255,255,255,1)',
        },
        ...this.config,
    }
  }

  info(message: string, callback: any, icon: any = null): void {

    if(icon)
        this.config.info.fontAwesomeClassName = icon;

    Notiflix.Notify.info(message, callback, this.config);

  }

}
