import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class PreciosService {

    constructor(public api: ApiService) {}

    findAll<T>(params: any = null): Observable<any> {
        return this.api.get(`precios/list/`, params);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('precios/add', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`precios/delete/${id}`);
    }
}
