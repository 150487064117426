<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} {{getClassInput()}}">

        <div class="row" *ngIf="value && value.length > 0">

            <div class="col-12">
                <div class="ui-image-list-content col-lg-12 form-group">
                    <owl-carousel-o [options]="options">

                        <ng-container *ngFor="let item of value; let i=index">
                            <ng-template carouselSlide>
                                <div class="ui-image-list-item">
                                    <div>
                                        <ui-image [filename]="item" [type]="type" [secure]="secure" [temp]="isNew(item)" [tagimg]="false" [height]="'320px'"></ui-image>
                                    </div>
                                    <button (click)="removeImagen(i)" class="btn btn-danger btn-round btn-sm ui-image-list-item-clear">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </ng-template>
                        </ng-container>

                    </owl-carousel-o>

                </div>
            </div>

        </div>

        <button input-image-crop [type]="type" [secure]="secure" (onChange)="addImagen($event)" class="btn btn-success btn-sm"><i
               class="fa fa-plus mr-2"></i>Nueva
            Imagen</button>


        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="formControlName"></ui-input-errors>

    </div>
</div>
