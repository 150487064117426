import { Component, OnInit, Input, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { ImagenesService } from '../../../@core/api/imagenes.service';
import { environment } from '../../.././../environments/environment';

interface DialogData {
    type: string
    titulo: string
    imageChangedEvent: any,
    openWindow: boolean,
    secure: boolean,
}
@Component({
    selector: 'input-image-crop-dialog',
    styleUrls: ['./input-image-crop-dialog.component.scss'],
    templateUrl: './input-image-crop-dialog.component.html'
})
export class InputImageCropDialogComponent implements OnInit {


    type: string;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    titulo: string;
    secure: boolean = false;
    aspectRatio: number = 4 / 3;
    maintainAspectRatio: boolean = false;
    minWidth: number = 128;
    minHeight: number = 128;

    widthReady: number = 128;
    heightReady: number = 128;
    typeReady: string = 'none';
    sizeReady: string = '0KB';
    imageInfo: string = "";

    widthCropper: number;
    heightCropper: number;
    images_config: any;

    @ViewChild('inputTitulo') inputTitulo: ElementRef;
    @ViewChild('inputRef', { static: true }) inputRef: ElementRef;
    public loading: boolean = false;

    constructor(
        public _elementRef: ElementRef,
        public _imagenesService: ImagenesService,
        public _dialogRef: MatDialogRef<InputImageCropDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dataDialog: DialogData
    ) {

    }


    ngOnInit() {
        this.type = this.dataDialog?.type;
        this.titulo = this.dataDialog?.titulo;
        this.secure = this.dataDialog?.secure;

        this.images_config = environment.images[this.type] ? environment.images[this.type] : {};
        if (this.images_config.aspectRatio) {
            this.maintainAspectRatio = true;
            this.aspectRatio = this.images_config.aspectRatio;
        }
        if (this.images_config.minWidth) {
            this.minWidth = this.images_config.minWidth;
        }
        if (this.images_config.minHeight) {
            this.minHeight = this.images_config.minHeight;
        }

        const el: HTMLInputElement = this._elementRef.nativeElement.querySelector('#ui-input-image-crop-dialog');
        el.value = this.titulo;
        if (this.dataDialog.openWindow) {
            this.inputRef.nativeElement.click();
        } else {
            this.fileChangeEvent(this.dataDialog.imageChangedEvent);
        }
    }

    btnOpenWindow() {
        this.inputRef.nativeElement.click();
    }

    fileChangeEvent(event: any): void {

        let self = this;
        const selectedFile = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = function (event) {
            //console.log("RESULT IMAGE", event.target.result);
            let image: HTMLImageElement = new Image();
            image.src = `${event.target.result}`;
            image.addEventListener("load", function() {
                // Concatenatar nuestro HTML image info
                var imageInfo = image.width + '×' + // Obtener el ancho de nuestra imagen
                    image.height + ' ' +
                    selectedFile.type + ' ' +
                    Math.round(selectedFile.size / 1024) + 'KB';
                self.imageInfo = imageInfo;
                self.typeReady = selectedFile.type;
                self.sizeReady = Math.round(selectedFile.size / 1024) + 'KB';
                self.setImage(image);
                // Finally append our created image and the HTML info string to our `#preview`
            });
        };
        this.imageChangedEvent = event;
    }

    setImage(image: HTMLImageElement){
        this.heightReady = image.height;
        this.widthReady = image.width;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        //console.log(event, base64ToFile(event.base64));
    }

    upload() {

        if (this.uploadPermisible()) {
            this.loading = true;
            let formData: any = new FormData();

            let type = this.images_config?.type;
            let secure = this.images_config?.secure;

            formData.append("tipo", type);
            formData.append("file", this.croppedImage);
            const service = secure ? this._imagenesService.upload_secure(formData) : this._imagenesService.upload(formData);
            service.subscribe(r => {
                const el: HTMLInputElement = this._elementRef.nativeElement.querySelector('#ui-input-image-crop-dialog');
                this._dialogRef.close({ filename: r.name_file, titulo: el.value });
                this.loading = false;
            }, e => {
                // console.log(e);
                this._dialogRef.close({ filename: null });
                this.loading = false;
            });
        }
    }

    uploadPermisible() {
        if (this.minWidth > this.widthReady) {
            return false;
        } else if (this.minHeight > this.heightReady) {
            return false;
        }
        return true;
    }

    imageLoaded() {
        this.showCropper = true;
        //console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        //console.log('Cropper ready', sourceImageDimensions);
        if (this.minWidth > this.widthReady || this.minHeight > this.heightReady) {

            this.imageChangedEvent = null;
        }
    }

    loadImageFailed() {
        //console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    rotationHorario() {
        this.rotation++;
        this.updateRotation();
    }

    rotationAntihorario() {
        this.updateRotation();
        this.rotation--;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }

}
function imageCropped(event: Event, ImageCroppedEvent: any) {
    throw new Error('Function not implemented.');
}

