import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class ExtrasService {

  constructor(public api: ApiService) {

  }

  findAll<T>(params: any = null): Observable<any> {
    const {publicacion_id} = params;
    return this.api.get(`extras/list/${publicacion_id}`, params);
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`extras/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('extras/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('extras/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`extras/delete/${id}`);
  }
}
