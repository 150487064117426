import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class OperadorTuristicoService {

  public $data: Array<any>;

  constructor(public api: ApiService){

  }

  findAll<T>(params = null): Observable<any> {
    return new Observable((observer) => {
      this.api.get('operador-turistico', params).subscribe((r) => {
        this.$data = r.data;
        return observer.next(r);
      }, (err) => {
        observer.error(err);
      });
    });
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`operador-turistico/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('operador-turistico/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('operador-turistico/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`operador-turistico/delete/${id}`);
  }
}
