import {Component, OnInit} from '@angular/core';
import {NbAuthService, NbAuthJWTToken, NbTokenStorage} from '@nebular/auth';
import {Router} from '@angular/router';

@Component({
    selector: 'ngx-auth-logout',
    template: `LOGOUT...`,
})
export class UiAuthLogoutComponent implements OnInit {

    user: any;

    constructor(
        private authService: NbAuthService, private _router: Router) {

        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {

                if (token.isValid()) {
                    this.user = token.getPayload();
                }
            });
    }

    ngOnInit(): void {
        localStorage.clear();
        this._router.navigate(['/']);
    }
}
