<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">
            <!--      <img  src="assets/images/logo.png" alt="Locat Travel Club" style="height:50px;" />-->
            <img src="assets/images/logo.svg" alt="Locat Travel Club" style="height:26px;" />
        </a>
    </div>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
</div>

<div class="header-container header-icons">
    <nb-actions size="medium">

        <!--
        <nb-action class="control-item">
        <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action class="control-item" icon="email-outline">

        </nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action>-->

        <!--<nb-action class="control-item" icon="refresh" (click)="refresh()"></nb-action>

        <nb-action class="control-item" [icon]="isConnected ? 'wifi':'wifi-off'" ></nb-action>
        -->
        <nb-action class="control-item">
            <div class="dropdown">
                <a href="#" (click)="updateView('saled')" class="icon-header position-relative" id="notifi-shooping" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-shopping-cart icon-notify"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {{ countNotifyView.saled > 9 ? '+9' : countNotifyView.saled }}
                    </span>
                </a>
                <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel" style="font-weight: 100 !important;">
                    <div class="notification-heading">
                        <div class="menu-title">Notificaciones de reservas y ventas</div>
                        <div class="menu-title all float-right" (click)="redirect('pages/libro-ventas')">Todo<i class="glyphicon glyphicon-circle-arrow-right"></i></div>
                    </div>
                    <li class="divider"></li>
                    <div class="notifications-wrapper">
                        <div *ngIf="notifySaled.length > 0;else noNotify">
                            <div class="search-results" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="240" [scrollWindow]="false" (scrolled)="onScrollDown('saled')">
                                <a class="content" (click)="redirect('pages/libro-ventas/ver/' + sale.token)" *ngFor="let sale of notifySaled">
                                    <div class="notification-item">
                                        <div class="item-title">{{ sale.fullname }}</div>
                                        <p class="item-info">{{ sale.text }}</p>
                                        <div class="item-time">{{ sale.time | date: 'd/M/yy, h:mm a' }}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <li class="divider"></li>
                        <!--
                        <div class="notification-footer">
                            <h4 class="menu-title">View all<i class="glyphicon glyphicon-circle-arrow-right"></i></h4>
                        </div>
                        -->
                    </div>
                </ul>
            </div>
        </nb-action>

        <nb-action class="control-item">
            <div class="dropdown">
                <a href="#" (click)="updateView('repayment')" class="icon-header position-relative" id="notifi-shooping" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-hand-holding-usd icon-notify"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {{ countNotifyView.repayment > 9 ? '+9' : countNotifyView.repayment }}
                    </span>
                </a>
                <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel" style="font-weight: 100 !important;">
                    <div class="notification-heading">
                        <div class="menu-title">Notificaciones de devoluciones</div>
                        <div class="menu-title all float-right" (click)="redirect('pages/libro-ventas')">Todo<i class="glyphicon glyphicon-circle-arrow-right"></i></div>
                    </div>
                    <li class="divider"></li>
                    <div class="notifications-wrapper">
                        <div *ngIf="notifyRepaymented.length > 0;else noNotify">
                            <div class="search-results" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="240" [scrollWindow]="false" (scrolled)="onScrollDown('repayment')">
                                <a class="content" (click)="redirect('pages/libro-ventas/ver/' + repayment.token)" *ngFor="let repayment of notifyRepaymented">
                                    <div class="notification-item">
                                        <div class="item-title">{{ repayment.fullname }}</div>
                                        <p class="item-info">{{ repayment.text }}</p>
                                        <div class="item-time">{{ repayment.time | date: 'd/M/yy, h:mm a' }}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <li class="divider"></li>
                        <!--
                        <div class="notification-footer">
                            <h4 class="menu-title">View all<i class="glyphicon glyphicon-circle-arrow-right"></i></h4>
                        </div>
                        -->
                    </div>
                </ul>
            </div>
        </nb-action>

        <nb-action class="control-item" icon="message-circle-outline" options="{style: white}" badgeText="{{ countChatView }}" badgePosition="bottom right" badgeStatus="danger" (click)="goChatVentas()"></nb-action>

        <nb-action class="user-action">
        <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']"> -->
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.nombre" [picture]="user?.picture">
            </nb-user>
        </nb-action>
    </nb-actions>

</div>

<ng-template #noNotify>
    <div class="search-results">
        <a class="content">
            <div class="notification-item">
                <div class="item-title"> No hay ninguna notificación</div>
            </div>
        </a>
    </div>
</ng-template>