import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class ServiciosService {

    public $data: Array<any>;

    constructor(public api: ApiService) {

    }

    findAll<T>(params: any = null): Observable<any> {
        const {publicacion_id} = params;
        return this.api.get(`servicios/list/${publicacion_id}`, params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`servicios/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('servicios/add', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('servicios/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`servicios/delete/${id}`);
    }
}
