import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbIconLibraries, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { UserData } from '../../../@core/data/users';
import { LayoutService, NotiflixService, HowlerService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { Router, NavigationEnd } from '@angular/router';
import { ChatVentasService } from '../../../@core/sockets/chatventas.service';
import { ChatVentas } from '../../../@core/sockets/chatventas';
import { ChatVentasList } from '../../../@core/sockets/chatventasList';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiChatService } from '../../../@core/api/apiChat.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  notifySaled: any[] = [];
  notifyRepaymented: any[] = [];
  page = {
    ventas: 1,
    devolucion: 1,
  };
  countNotifyView = {
    saled: 0, // reservas y pagos
    repayment: 0, // devoluciones
  }
  user: any;
  url_currenty: string = "";
  countChatView: string = '';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  userMenu = [{ title: 'Log out', link: 'auth/logout' }];
  isConnected: boolean = true;

  private subscriptions = new Subscription();
  public rooms: ChatVentasList;
  public token: string;
  public headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    public http: HttpClient,
    private _router: Router,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private connectionService: ConnectionService,
    private chatVentasService: ChatVentasService,
    private userService: UserData,
    private notiflixService: NotiflixService,
    private howlerService: HowlerService,
    public apiChat: ApiChatService,
    private iconLibraries: NbIconLibraries
    ) {

      /*this.connectionService.monitor().subscribe( isConnected => {
        this.isConnected = isConnected;
      });*/
      this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fas' });

      this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        }
      });

      this.rooms = this.chatVentasService.rooms;
      this.token = this.chatVentasService.token;

      this._router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
            this.url_currenty = event.urlAfterRedirects;
        }
      })

  }

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


    this.chatVentasService.joinAdminRoom();
    this.countNotView();
    this.getNotificaciones('saled');
    this.getNotificaciones('repayment');

    // Notificaciones chat
    this.subscriptions.add(
        this.chatVentasService.onMessageAdmin().subscribe((m: any) => {
            if(this.url_currenty.indexOf(`/pages/chat-ventas/rooms/${m.user.room}`) !== 0 && !m.message.user.isAdmin) {
                const minutes = 1;
                this.notifyAfterMinute(minutes, 'chat_' + m.user.room);

                // Notificar en vista
                this.notiflixService.configChat();
                const message = m.message.user.name.toUpperCase() + ': ' + (m.message.text !+ "" ? m.message.text : 'Sin mensaje');
                this.notiflixService.info(message, () => {
                    this._router.navigate([`/pages/chat-ventas`]).then(() => {
                        this._router.navigate([`pages/chat-ventas/rooms/${m.user.room}`]);
                    });
                });
            }
        })
    );

    // contador de chats
    this.subscriptions.add(
        this.chatVentasService.onMessageViewCountChat().subscribe((data: any) => {
            if(data.success) {
                if(data.result._count.view > 9)
                    this.countChatView = '+9';
                else
                    this.countChatView = data.result._count.view;
            }
        })
    );

    this.subscriptions.add(
        this.chatVentasService.onMessageViewPlusChat().subscribe((data: any) => {

            if(this.countChatView != '+9' && this.countChatView != '') {
                let numChat = parseInt(this.countChatView) + 1;
                if(numChat > 9)
                    this.countChatView = '+9';
                else
                    this.countChatView = numChat + '';
            } else
                if(this.countChatView == '') {
                    this.countChatView = '0';
                    let numChat = parseInt(this.countChatView) + 1;
                    this.countChatView = numChat + '';
                }
        })
    );

    // Iniciar Notificacion pago
    this.subscriptions.add(
        this.chatVentasService.onNotificationSaled().subscribe((data: any) => {
            const minutes = 1;
            this.notifyAfterMinute(minutes, 'reseved_' + data.token);

            // agregar +1 contador de vistas
            this.countNotifyView.saled++;
            if(this.countNotifyView.saled > 9)
                this.countNotifyView.saled = 10;

            // Notificar en vista
            this.notiflixService.configChat();
            this.notifySaled.unshift(data);
            const message = data.text;
            const icon = "fas fa-shopping-cart parpadea-primary";
            this.notiflixService.info(message, () => {
                this._router.navigate([`pages/libro-ventas/ver/${data.token}`]);
            }, icon);
        })
    );

    // Iniciar Notificacion devolucion
    this.subscriptions.add(
        this.chatVentasService.onNotificationRepayment().subscribe((data: any) => {
            const minutes = 1;
            this.notifyAfterMinute(minutes, 'repayment_' + data.token);

            // agregar +1 contador de vistas
            this.countNotifyView.repayment++;
            if(this.countNotifyView.repayment > 9)
                this.countNotifyView.repayment = 10;

            // Notificar en vista
            this.notiflixService.configChat();
            this.notifyRepaymented.unshift(data);
            const message = data.text;
            const icon = "fas fa-hand-holding-usd parpadea-primary";
            this.notiflixService.info(message, () => {
                this._router.navigate([`pages/libro-ventas/ver/${data.token}`]);
            }, icon);
        })
    );

    // Actualizar notificaciones de reservas y pagos desde libro de ventas
    this.subscriptions.add(
        this.chatVentasService.onNotificationSaledUpdate().subscribe((data: number) => {
            this.countNotifyView.saled = data;
        })
    );

    // Actualizar notificaciones de reservas y pagos desde libro de ventas
    this.subscriptions.add(
        this.chatVentasService.onNotificationRepaymentUpdate().subscribe((data: number) => {
            this.countNotifyView.repayment = data;
        })
    );

  }


  getNotificaciones(type: string): void {
    switch (type) {
        case 'saled':
            this.apiChat.get('notifications/saled', {page: this.page.ventas})
                .subscribe((res) => {
                    if(res.success){
                        res.result.notificaciones.map((val) => {
                            this.notifySaled.push(val);
                        });
                        if(res.result.not_views > 9)
                            this.countNotifyView.saled = 10;
                        else
                            this.countNotifyView.saled = res.result.not_views;
                    }
                });
            break;
        case 'repayment':
            this.apiChat.get('notifications/repayment', {page: this.page.devolucion})
                .subscribe((res) => {
                    if(res.success){
                        res.result.notificaciones.map((val) => {
                            this.notifyRepaymented.push(val);
                        });
                        if(res.result.not_views > 9)
                            this.countNotifyView.repayment = 10;
                        else
                            this.countNotifyView.repayment = res.result.not_views;
                    }
                });
            break;

        default:
            break;
    }
  }


  updateView(type: string): void {
    switch (type) {
        case 'saled':
            this.apiChat.patch('notifications/saled', {}).toPromise()
                .then((res) => {
                    this.countNotifyView.saled = 0;
                });
            break;
        case 'repayment':
            this.apiChat.patch('notifications/repayment', {}).toPromise()
                .then((res) => {
                    this.countNotifyView.repayment = 0;
                });
            break;
        default:
            break;
    }
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  refresh(){
    location.href = location.href;
  }

  goChatVentas(){
    this._router.navigate([`pages/chat-ventas`]);
  }

  /**
   * REST - Obtener cantidad de chat no vistos
   */
  countNotView(): void {
    this.apiChat.get('message/not-view', {})
        .subscribe((res) => {
            if(res.success) {
                if(res.result._count.view > 9)
                    this.countChatView = '+9';
                else
                    this.countChatView = res.result._count.view ?? '';
            }
        });
  }

  /**
   * Notificar despues de un minuto
   * @param minutes
   */
  notifyAfterMinute(minutes: number, room: string): void {
    let saved = Number(sessionStorage.getItem(room));
    if (saved) {
        let time = new Date().getTime();
        if(time - saved > minutes * 60 * 1000) {
            // emitir sonido notificacion
            this.howlerService.chatMessage();
            sessionStorage.setItem(room, time.toString());
        }
    } else {
        // emitir sonido notificacion
        this.howlerService.chatMessage();
        sessionStorage.setItem(room, (new Date().getTime()).toString());
    }
  }

  /**
   * redireccionar
   */
  redirect(url) {
    this._router.navigate([url]);
  }


  onScrollDown(type: string) {
    switch (type) {
        case 'saled':
            this.page.ventas++;
            this.getNotificaciones('saled');
            break;
        case 'repayment':
            this.page.devolucion++;
            this.getNotificaciones('repayment');
            break;
        default:
            break;
    }

  }

}
