<div *ngIf="!inline" class="{{classContainer}} {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>

    <div class="{{classHorizonalInput()}} {{bindLabel}}">
        <ng-select #inputselect [class]="getClassInput()" [items]="items" [typeahead]="typeahead" [multiple]="multiple" [loading]="loading" (blur)="onTouched()" (change)="change($event)" (add)="add($event)" [bindLabel]="bindLabel" [bindValue]="bindValue" (blur)="markAsTouched()">
        </ng-select>
        <ui-input-errors [form]="formGroup" [formControl]="formControl" [formControlName]="formControlName" [title]="getName()">
        </ui-input-errors>
    </div>
</div>

<div *ngIf="inline" class="ui-input-container">
    <ng-select [name]="getName()" [items]="items" [loading]="loading" (change)="change($event)" [bindLabel]="alias" [bindValue]="id"></ng-select>
</div>