import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class FechasAbiertasService {

    constructor(public api: ApiService) {}

    findAll<T>(params: any = null): Observable<any> {
        return this.api.get(`horario/list/dia-semana`, params);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('horario/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`horario/delete/${id}`);
    }

    clonar<T>(diaSemana: string, idPublicacion: number): Observable<any> {
        return this.api.get(`horario/clonar/${diaSemana}/${idPublicacion}`);
    }
}
