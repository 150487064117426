import { Component, OnInit, Input, SimpleChanges, OnChanges, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { InputImageCropDialogComponent } from './input-image-crop-dialog.component';

@Component({
  selector: 'input-image-crop',
  styleUrls: ['./input-image-crop.component.scss'],
  templateUrl: './input-image-crop.component.html'
})
export class InputImageCropComponent implements OnChanges {

    @Input('temporal') temporal: boolean = false;
    @Input('filename') filename: string;
    @Input('titulo') titulo: string = "";
    @Input('type') type: string;
    @Input() secure: boolean = false;
    @Input('size') size: string = "";
    @Input('style') style: string;
    @Input('class') class: string = "ui-image-button";
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
    //public istemp: boolean = false;
    public loading: boolean = false;

    @ViewChild('inputRef', { static: true }) inputRef: ElementRef;

    constructor(
        public _dialog: MatDialog,
    ) {
        

    }    

    ngOnChanges(changes: SimpleChanges) {
        if(changes.filename?.currentValue != changes.filename?.previousValue){
            this.filename = changes.filename.currentValue;
        }
    }

    clickImage(){
        this.inputRef.nativeElement.click();
    }

    openDialog(event: any) {
        const dialogRef = this._dialog.open(InputImageCropDialogComponent, {
            width: '100vw',
            height: '95vh',
            data: { imageChangedEvent: event, type: this.type, titulo: this.titulo, secure: this.secure },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if(result.filename){
                    this.filename = result.filename;
                    this.titulo = result.titulo;
                    this.temporal = true;
                    const dat = {
                        filename: this.filename,
                        titulo: this.titulo
                    };
                    this.onChange.emit(dat);
                }else{

                }
            }
            this.loading = false;
        });
    }

    fileChangeEvent(event: any): void {
        this.openDialog(event);
        //this.imageChangedEvent = event;
    }

    

}
