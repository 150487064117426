import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class CambioMonedaService {

  constructor(public api: ApiService){

  }

  findAll<T>(params: any = null): Observable<any> {
    return this.api.get(`cambio-moneda/`, params);
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`cambio-moneda/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('cambio-moneda/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('cambio-moneda/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`cambio-moneda/delete/${id}`);
  }
}
