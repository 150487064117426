<div class="form-group {{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} {{getClassInput()}}">

        <div class="row" *ngIf="value && value.length > 0">

            <div class="col-12">
                <div class="ui-image-list-content col-lg-12 form-group">
               
<swiper-container thumbs-swiper=".mySwiper2" #Slider slides-per-view="1">
    <swiper-slide *ngFor="let item of value; let i=index">
  
            <div class="ui-image-list-item">
                <div>
                    <ui-image class="slide-image" [filename]="item" [type]="type" [secure]="secure" [bg_mode]="'contain'" [temp]="isNew(item)" [tagimg]="false" [height]="'25vw'"></ui-image>
                </div>
                <button (click)="removeImagen(i)" class="btn btn-danger btn-round btn-sm ui-image-list-item-clear btn-delete">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
    </swiper-slide>
  
  </swiper-container>
  <swiper-container class="mySwiper2" #ThumbsSlider space-between="10"  slides-per-view="auto" breakpoints="{{breakpoints}}" free-mode="true"
    watch-slides-progress="true">
    <swiper-slide *ngFor="let item of value; let i=index">
        <div class="ui-image-list-item">
            <div>
                <ui-image [filename]="item" [type]="type" [secure]="secure" [temp]="isNew(item)" [tagimg]="false" [enable_dialog]="false" [width]="'80px'" [height]="'80px'" [bg_mode]="'100% 100%'"></ui-image>
            </div>
        </div>
</swiper-slide>
</swiper-container>

                </div>
            </div>

        </div>

        <button input-image-crop [type]="type" [secure]="secure" (onChange)="addImagen($event)" class="btn btn-success btn-sm"><i
               class="fa fa-plus mr-2"></i>Nueva
            Imagen</button>


        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="formControlName"></ui-input-errors>

    </div>
</div>
