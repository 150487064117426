import {Injectable} from '@angular/core';
import {Observable, Subject, combineLatest} from 'rxjs';
import {SubcategoriaService} from '../api/subcategoria.service';
import {OperadorTuristicoService} from '../api/operador-turistico.service';
import {IdiomasService} from '../api/idiomas.service';
import {TipoBeneficioService} from '../api/tipo-beneficio.service';
import {CurrencyService} from '../api/currency.service';
import {TipoCobroService} from '../api/tipo-cobro.service';
import {TipoDocumentoService} from '../api/tipo-documento.service';
import {PaisesService} from '../api/paises.service';
import {RolesService} from '../api/roles.service';
import {RegistrosService} from '../api/registros.service';
import {EstilosService} from '../api/estilos.service';
import {CategoriaService} from '../api/categoria.service';
import {DiasSemanaService} from '../api/dias-semana.service';

@Injectable()
export class SettingsService {
    constructor(
        public _subcategoria: SubcategoriaService,
        public _categoria: CategoriaService,
        public _estilo: EstilosService,
        public _operadorTuristico: OperadorTuristicoService,
        public _idiomas: IdiomasService,
        public _tipoBeneficio: TipoBeneficioService,
        public _monedas: CurrencyService,
        public _tipoCobro: TipoCobroService,
        public _tipoDocumento: TipoDocumentoService,
        public _paises: PaisesService,
        public _currency: CurrencyService,
        public _roles: RolesService,
        public _registros: RegistrosService,
        public _diasSemana: DiasSemanaService,
    ) {

    }

    /**
     * suscribeLoadservices
     */
    public suscribeLoadservices(): Observable<any> {
        return combineLatest(
            this._idiomas.findAll(),
            this._tipoBeneficio.findAll(),
            this._subcategoria.findAll(),
            this._categoria.findAll(),
            this._estilo.findAll(),
            this._operadorTuristico.findAll(),
            this._monedas.findAll(),
            this._tipoCobro.findAll(),
            this._paises.findAll(),
            this._tipoDocumento.findAll(),
            this._currency.findAll(),
            this._roles.findAll(),
            this._diasSemana.findAll(),
            this._registros.findBy({id: this._registros.$REGISTROS_SALA_VENTA}),
            this._registros.findBy({id: this._registros.$REGISTROS_TIPO_VENTA}),
            this._registros.findBy({id: this._registros.$REGISTROS_IDIOMAS}),
            this._registros.findBy({id: this._registros.$REGISTROS_CAMPANYA}),
            this._registros.findBy({id: this._registros.$REGISTROS_ENVIAR_CORREO}),
            this._registros.findBy({id: this._registros.$REGISTROS_PLATAFORMA_RESERVA}),
            this._registros.findBy({id: this._registros.$REGISTROS_ESTADO_PAGO}),
            this._registros.findBy({id: this._registros.$REGISTROS_ESTADO_COMPRA}),
            this._registros.findBy({id: this._registros.$REGISTROS_MENSAJE_CHAT}),
        ) as Observable<any>;
    }

    getIdiomas(): Array<any> {
        return this._idiomas.$data.filter(v => v.isEnabled) as Array<any>;
    }

    getMonedas(): Array<any> {
        return this._monedas.$data as Array<any>;
    }

    getTipoCobros(): Array<any> {
        return this._tipoCobro.$data as Array<any>;
    }

    getSubcategorias(): Array<any> {
        return this._subcategoria.$data as Array<any>;
    }

    getCategorias(): Array<any> {
        return this._categoria.$data as Array<any>;
    }

    getOperadores(): Array<any> {
        return this._operadorTuristico.$data as Array<any>;
    }

    getTipobeneficios(): Array<any> {
        return this._tipoBeneficio.$data as Array<any>;
    }

    getCurrency(): Array<any> {
        return this._currency.$data as Array<any>;
    }

    getCurrencyEnabled(): Array<any> {
        return this._currency.$data.filter(v => v.isEnabled) as Array<any>;
    }

    getPaises(): Array<any> {
        return this._paises.$data as Array<any>;
    }

    getTipoDocumentos(): Array<any> {
        return this._tipoDocumento.$data as Array<any>;
    }

    getEstados(): Array<any> {
        return [{id: 'true', label: 'Habilitado'}, {id: 'false', label: 'Deshabilitado'}] as Array<any>;
    }

    getRoles(): Array<any> {
        return this._roles.$data as Array<any>;
    }

    getRegistrosCanalesVenta(): Array<any> {
        return this._registros.$dataSalaVenta as Array<any>;
    }

    getRegistrosTipoVenta(): Array<any> {
        return this._registros.$dataTipoVenta as Array<any>;
    }

    getRegistrosIdiomas(): Array<any> {
        return this._registros.$dataIdiomas as Array<any>;
    }

    getRegistrosCampanya(): Array<any> {
        return this._registros.$dataCampanya as Array<any>;
    }

    getRegistrosEnviarCorreo(): Array<any> {
        return this._registros.$dataEnviarCorreo as Array<any>;
    }

    getRegistrosPlataformaReserva(): Array<any> {
        return this._registros.$dataPlataformaReserva as Array<any>;
    }

    getRegistrosEstadoPago(): Array<any> {
        return this._registros.$dataEstadoPago as Array<any>;
    }

    getRegistrosEstadoCompra(): Array<any> {
        return this._registros.$dataEstadoCompra as Array<any>;
    }

    getEstilos(): Array<any> {
        return this._estilo.$data as Array<any>;
    }

    getDiasSemana(): Array<any> {
        return this._diasSemana.$data as Array<any>;
    }

    getMensajesChat(): Array<any> {
        return this._registros.$dataMensajeChat as Array<any>;
    }
}
