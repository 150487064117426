import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup, FormArray, NgControl } from '@angular/forms';

@Component({
  selector: 'ui-input-errors',
  templateUrl: "input-errors.component.html",
  styleUrls: ["input-errors.component.scss"]
})
export class UiInputErrorsComponent implements OnChanges {

  @Input() role: string = "";
  @Input('title') title: string;
  @Input('name') name: string;
  @Input('formArrayName') formArrayName: string;
  @Input('formGroupName') formGroupName: string;
  @Input('formControlName') formControlName: string;
  @Input() formControl: FormGroup | FormArray | FormControl;
  @Input() form: FormGroup | FormArray;

  displayErrors(): boolean {
    return this.existFormControlErrors();
  }

  updateFormControl() {
    if (this.form) {
      //console.log("UiInputErrorsComponent:updateFormControl",this.formControlName, this.name, this.form);
      if (this.formControlName) {
        this.formControl = this.form.get(this.formControlName) as FormControl;
      } else if (this.formGroupName) {
        this.formControl = this.form.get(this.formGroupName) as FormGroup;
      } else if (this.formArrayName) {
        this.formControl = this.form.get(this.formArrayName) as FormArray;
      } else if(this.name) {
        this.formControl = this.form.get(this.name) as FormArray | FormGroup | FormControl;
      }
    }
  }

  existFormControlErrors(): boolean {
    if (this.formControl && (this.formControl?.touched || this.formControl?.dirty) && this.formControl?.invalid) {
      return true;
    }
    return false;
  }

  getName(){
    if(this.title){
      return this.title;
    }else if(this.formControlName){
      return this.formControlName;
    }else if (this.name){
      return this.name;
    }else {
      return "Dato";
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.name?.currentValue != changes.name?.previousValue) {
      this.name = changes.name?.currentValue;
    }
    if (changes.form?.currentValue != changes.form?.previousValue) {
      this.form = changes.form?.currentValue;
      this.updateFormControl();
    }
    if (changes.formControl?.currentValue != changes.formControl?.previousValue) {
      this.formControl = changes.formControl?.currentValue;
    }
  }

}