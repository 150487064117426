<div *ngIf="displayErrors()" class="ui-input-errors" [role]="role"> 
    <small *ngIf="formControl.errors?.required">{{ getName() }} es requerido. </small>
    <small *ngIf="formControl.errors?.email">Email inválido. </small>   
        
    <small *ngIf="formControl.errors?.minlength">Mínimo de {{formControl.errors?.minlength.requiredLength}} caracteres.</small>
    <small *ngIf="formControl.errors?.maxlength">Máximo de {{formControl.errors?.maxlength.requiredLength}} caracteres.</small>
    <small *ngIf="formControl.errors?.max">Valor máximo es {{formControl.errors?.max.max}}. </small>
    <small *ngIf="formControl.errors?.min">Valor mínimo es {{formControl.errors?.min.min}}. </small>        
</div>
<!--
<pre>{{formControl.value | json }}</pre>
<pre>{{formControl.errors | json }}</pre>
<pre>is touched? {{formControl.touched }}</pre>
<pre>is dirty? {{formControl.dirty }}</pre>
<pre>is invalid? {{formControl.invalid }}</pre>
-->