import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class TiposBoletosService {

    constructor(public api: ApiService) {

    }

    findAll<T>(params: any = null): Observable<any> {
        const {publicacion_id} = params;
        return this.api.get(`tipo-boletos/list/${publicacion_id}`, params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`tipo-boletos/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('tipo-boletos/add', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('tipo-boletos/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`tipo-boletos/delete/${id}`);
    }
}
