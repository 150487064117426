import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class PoliticasTerminosService {

  constructor(public api: ApiService){

  }

  findAll<T>(params): Observable<any> {
    return this.api.get('politicas-terminos', params);
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`politicas-terminos/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('politicas-terminos/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('politicas-terminos/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`politicas-terminos/delete/${id}`);
  }

  habilitar<T>(id: number): Observable<any> {
    return this.api.post('politicas-terminos/habilitar/', {id:id});
  }
  
}
