import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class CuponesService {

  constructor(public api: ApiService){

  }

  findAllUsuarios<T>(params: any = null): Observable<any> {
    return this.api.get(`cupones/list-user/`, params);
  }

  findAll<T>(params: any = null): Observable<any> {
    const {idUser} = params;
    return this.api.get(`cupones/list/${idUser}`, params);
  }

  find<T>(id: number, params = null): Observable<any> {
    return this.api.get(`cupones/${id}`, params);
  }

  create<T>(data: any): Observable<any> {
    return this.api.post('cupones/add', data);
  }

  update<T>(data: any): Observable<any> {
    return this.api.post('cupones/edit/', data);
  }

  delete<T>(id: number): Observable<any> {
    return this.api.delete(`cupones/delete/${id}`);
  }
}
