<div *ngIf="!inline"  class="{{classHorizonalContent()}}">
    <label *ngIf="label" [for]="getName()" class="label col-form-label {{classHorizonalLabel()}}">{{label}}</label>
    <div class="{{classHorizonalInput()}} {{getClassImage()}}">
        <button *ngIf="type == 'button'" [class]="getClassInput()" (click)="openDialog()"><i class="fa fa-map-marker"></i> <span *ngIf="title" class="ml-2">{{title}}</span></button>
        <ui-map-position *ngIf="type == 'imagen'"  [lat]="getLat()" [lng]="getLng()" (onChange)="change($event)"></ui-map-position>
        <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="getName()"></ui-input-errors>
    </div>
</div>

<div *ngIf="inline" class="ui-input-container {{getClassInput()}}">
    <ui-map-position  [lat]="getLat()" [lng]="getLng()" (onChange)="change($event)"></ui-map-position>
    <ui-input-errors [form]="formGroup" [formControl]="formControl" [name]="getName()"></ui-input-errors>
</div>
