import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class PublicacionesService {

    constructor(public api: ApiService) {

    }

    findAll<T>(params): Observable<any> {
        return this.api.get('publicaciones', params);
    }

    find<T>(id: number, params = null): Observable<any> {
        return this.api.get(`publicaciones/${id}`, params);
    }

    create<T>(data: any): Observable<any> {
        return this.api.post('publicaciones/add', data);
    }

    update<T>(data: any): Observable<any> {
        return this.api.post('publicaciones/edit/', data);
    }

    delete<T>(id: number): Observable<any> {
        return this.api.delete(`publicaciones/delete/${id}`);
    }

    clonar<T>(id: number): Observable<any> {
        return this.api.get(`publicaciones/clonar/${id}`);
    }
}
