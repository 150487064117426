import { SimpleChanges, Inject } from '@angular/core';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { ApiService } from '../../../@core/api/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export const ROUTE_IMAGE = `${environment.routeUploads}`;
export const ROUTE_TEMP = `${environment.routeUploadsTemp}`;
export const ROUTE_SECURE = `${environment.routeSecure}`;


@Component({
  selector: 'ui-image',
  styleUrls: ['./image.component.scss'],
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit, OnChanges {

  @Input('temp') temp: string;
  @Input('filename') filename: string;
  @Input('title') title: string = "";
  @Input('type') type: string;
  @Input('size') size: string;
  @Input('height') height: string;
  @Input('width') width: string;
  @Input('style') style: string = "";
  @Input('class') class: string = "ui-image";
  @Input('tagimg') tagimg: boolean = true;
  @Input() bg_mode: string = "cover";
  @Input() enable_dialog: boolean = true;

  public token: any;
  public images_config: any;
  public uri: string = "";
  public aditionalstyle = "background-size: cover;background-repeat: no-repeat;background-position: center;background: url('./../.././../../assets/images/default-image.png');";

  constructor(private api: ApiService, public _dialog: MatDialog) {
    this.token = this.api.getToken();
  }

  ngOnInit() {
    //this.getUri();
  }

  getStyle() {
    let style = this.aditionalstyle;
    if(this.style){
      style = `${style}${this.style}`;
    }
    if(this.width){
      style = `width:${this.width};${style}`;
    }
    if(this.height){
      style = `height:${this.height};${style}`;
    }
    return style;
  }

  openDialog(){
    let url = this.getRealUri();
    // console.log("URL", url);
    const dialogRef = this._dialog.open(ImageDialogComponent, {
      width: '100vw',
      height: '95vh',
      data: { url: url },
  });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filename?.currentValue != changes.filename?.previousValue) {
      this.filename = changes.filename.currentValue;
      this.getUri();
    }
    if (changes.style?.currentValue != changes.style?.previousValue) {
      this.style = changes.style.currentValue;
    }
  }

  getRoute(){
    this.images_config = environment.images[this.type] ? environment.images[this.type] : {} ;
    let secure = this.images_config?.secure || false;
    let path = ROUTE_TEMP;
    if(!this.temp){
      let type = this.images_config?.type;
      if(secure){
        path = `${ROUTE_SECURE}${type}/`;
      }else{
        path = `${ROUTE_IMAGE}${type}/`;
      }
    }
    return path;
  }

  getUri() {
    this.images_config = environment.images[this.type] ? environment.images[this.type] : {} ;
    this.uri = "/assets/images/default-image.png";
    if(!this.filename){
      return this.uri;
    }
    let prefix = this.size && this.images_config?.prefix[this.size] ? this.images_config?.prefix[this.size] : null;
    let secure = this.images_config?.secure || false;
    let route = this.getRoute();
    prefix = prefix ? `${prefix}` : '';
    let token = secure ? `?token=${this.token}`:``;
    this.uri = `${route}${prefix}${this.filename}${token}`;
  }

  getRealUri() {
    this.images_config = environment.images[this.type] ? environment.images[this.type] : {} ;
    if(!this.filename){
      return "/assets/images/default-image.png";
    }
    let secure = this.images_config?.secure || false;
    let route = this.getRoute();
    let token = secure ? `?token=${this.token}`:``;
    return  `${route}${this.filename}${token}`;
  }

}


@Component({
  selector: 'ui-image-dialog',
  styleUrls: ['./image.component.scss'],
  template: `
  <div class="ui-image-dialog">
    <img [src]="url" />
    <div>
  `
})
export class ImageDialogComponent implements OnInit {

  url:string;

  constructor(@Inject(MAT_DIALOG_DATA) public dataDialog: any){

  }

  ngOnInit() {
    this.url = this.dataDialog?.url;
  }

}

